import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="column">
  <img src="./benefit-graphic-slippage.png" />
  <h6>Lower Slippage.</h6>
  <p>Our open liquidity pool design enables higher capital efficiency and lower slippage rate comparing with other stableswaps.</p>
    </div>
    <div className="column">
  <img src="./benefit-graphic-scalability.png" />
  <h6>Higher Scalability.</h6>
  <p>Flexible pool composition allows each asset to scale naturally based on its organic supply.</p>
    </div>
    <div className="column">
  <img src="./benefit-graphic-ux.png" />
  <h6>Better User Experience.</h6>
  <p>You can always deposit and withdraw token in the same kind, without worrying about the pool compositions or pool size.</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      