/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import InvestorsContent from "../sections/investors-content"

import * as styles from './investors.module.scss'
import { CONTENT_CONTAINER_WIDTH, SECTION_HEADER_CONTAINER_MARGIN } from "../constants/constant"
import SectionHeader from "./section-header"

const Investors = ({
  offset, factor = 1,
  windowWidth,
  windowHeight,
}) => {
  console.log("Window Height >> ", windowHeight)
  const { allFile: { edges: data }, allMdx: { edges: dimensions } } = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "png" } }, sort: {fields: name, order: ASC}) {
        edges {
          node {
            publicURL
            name
            dir
          }
        }
      }
      allMdx(filter: {slug: {eq: "investors-content"}}) {
        edges {
          node {
            id
            frontmatter {
              dimensions
              ratios
            }
            slug
          }
        }
      }
    }
  `)
  const imgInvestors = data
    .filter(({ node: { dir, name }})=> {
      console.log('dir', dir)
      return dir.indexOf('investors') >= 0 && name.indexOf('investor') >= 0
    })
    .map(({ node: { publicURL, name }}) => {
      return {
        url: publicURL,
        name: name+'.png'
      }
    })
  const { node: { frontmatter: { dimensions: imgDimensions, ratios: imgRatios } } } = dimensions[0]
  const investorsLen = Math.min(imgInvestors.length, imgDimensions.length, imgRatios.length)
  const dataInvestors = imgInvestors
    .filter((imgData, index)=>index < investorsLen)
    .map(({ url, name }, i) => {
      return {
        url: url,
        name: name,
        width: imgDimensions[i],
        ratio: imgRatios[i]
      }
    })
  // console.log('$$%%$$%%$$')
  // console.log('$$$%%%%$$$')
  // console.log('$$$$%%$$$$')
  // console.log('$$ data $$')
  // console.log(data)
  // console.log('$$ investors $$')
  // console.log(dataInvestors)
  // console.table(dataInvestors)
  // console.log('$$ dimensions $$')
  // console.log(dimensions)
  // console.log('$$ imgDimensions $$')
  // console.log(imgDimensions)
  // console.log('$$ imgRatios $$')
  // console.log(imgRatios)

  const D1 = `88%`,
    D2 = `48%`,
    D3 = `30%`,
    D4 = `25%`,
    D5 = `19%`,
    D6 = `15%`,
    D7 = `13.5%`,
    D8 = `10%`,
    D9 = `8.5%`,
    D10 = `6%`

  return (
    <main>
      <Divider
        sx={{
          zIndex: 53,
          // background: `#FF053434`,
        }}
        speed={0.2}
        offset={offset + (
          windowWidth <= 640 ?
            windowHeight < 740 ?
              windowHeight < 680 ?
                windowHeight < 590 ?
                  factor * -0.07 :
                0 : // < 680
              factor * -0.07 : // < 740
            0 // >= 740
          : 0
        )}
        // offset={offset + (factor * 0.08)}
        factor={factor}
      >
        <SectionHeader
          height={[
            `${
              windowHeight < 590 ?
                `13` :
                windowHeight < 740 ?
                  `16` :
                  `14`
            }vh`,
            `16vh`,
            null,
            null,
            windowHeight < 830 ? `10.5rem` : `6.5rem`
          ]}
          headerMargin={SECTION_HEADER_CONTAINER_MARGIN}
          paddingTop={[
            windowHeight < 590 ?
              `0rem` :
              `1rem`,
            null,
            windowHeight < 830 ? `2rem` : `2rem`,
            windowHeight < 830 ? `2.2rem` : `2.2rem`,
            windowHeight < 830 ? `3.5rem` : 0
          ]}
          justifyContent={[`flex-start`]}
        >
          <InvestorsContent />
        </SectionHeader>
        <div sx={{
          width: CONTENT_CONTAINER_WIDTH,
          // paddingTop: `${windowHeight * factor * 0.5}px`
          // '.investor-logo-container': {
          //   '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10)': {
          //     // backgroundColor: '#FD564956',
          //     flexGrow: 1,
          //   },
          //   '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)': {
          //     flexBasis: `18%`
          //   },
          //   '&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10)': {
          //     flexBasis: `21%`
          //   },
          // }
        }} className={styles.investorsContainer}>
          {dataInvestors.map(({url, name, width, ratio}, index)=>{
            return (
              <div key={'investor-logo-container-' + index}
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  // height: [`3.5rem`, `auto`],

                  'img': {
                    display: `block`,
                    // margin: `0 auto`
                  },
                  ...([
                    { // 1 Three Arrows Capital
                      flexGrow: [1, null, 1],
                      flexBasis: [D1, D2, `50%`],
                      justifyContent: [`center`, `flex-end`],
                      marginTop: [0]
                    },
                    { // 2 DeFiance
                      flexGrow: [1, 0, 1, 1],
                      flexBasis: [D1, D2, D2],
                      justifyContent: [`center`, null, `flex-start`],
                      marginTop: [`2.3rem`, 0],
                      'img': {
                        marginLeft: [0, `2rem`, `4rem`]
                      }
                    },
                    { // 3 0xVenturesDAO
                      flexGrow: [1, 0],
                      flexBasis: [D2, `0%`, `0%`],
                      justifyContent: [`center`, `flex-start`],
                      marginTop: [`4rem`, `4rem`, `4rem`, `4rem`]
                    },
                    { // 4 Avalanche
                      flexGrow: [1, null, 1, 1, 1],
                      flexBasis: [D2, `26%`, D5, D5, D6],
                      justifyContent: [`center`, `flex-end`],
                      marginTop: [`4rem`, `4rem`, `4rem`, `4rem`]
                    },
                    { // 5 AvaLaunch
                      flexGrow: [1, null, 2, 2, 1],
                      flexBasis: [D2, `26%`, D5, D5, D6],
                      justifyContent: [`center`, `flex-end`, null, `flex-end`, `flex-end`],
                      marginTop: [`2rem`, `4rem`, `4rem`, `4rem`]
                    },
                    { // 6 Avatar
                      flexGrow: [1, null, 2, 2, 1],
                      flexBasis: [D2, `34%`, D4, D4, D5],
                      justifyContent: [`center`, `flex-end`, null, `flex-end`, `flex-end`],
                      marginTop: [`2rem`, `4rem`, `4rem`, `4rem`, `4rem`],
                    },
                    { // 7 AVenturesDAO
                      flexGrow: [1, null, 1, 1, 1],
                      flexBasis: [D2, D8, D5, D5, D6],
                      justifyContent: [`center`, `flex-start`, `flex-end`],
                      marginTop: [`2rem`, `2rem`, `4rem`, `4rem`, `4rem`]
                    },
                    { // 8 Benqi
                      flexGrow: [1, null, 0, 0, 1],
                      flexBasis: [D2, D4, `0%`, `0%`, D5], // [48%, 30%, 25%, null, 20%]
                      justifyContent: [`center`, `flex-end`, `flex-start`, null, `flex-end`],
                      marginTop: [`1.8rem`, `2rem`, `2.6rem`, `2.4rem`, `4rem`]
                    },
                    { // 9 CMS
                      flexGrow: [1, null, 2, 1, 0],
                      flexBasis: [D2, D4, D4, D4, D10], // [48%, 30%, 0%, 25%, 20%]
                      justifyContent: [`center`, `flex-end`, null, `flex-end`, `flex-start`],
                      marginTop: [`1.8rem`, `2rem`, `2.6rem`, `2.4rem`, `2.5rem`]
                    },
                    { // 10 Colony
                      flexGrow: [1, null, 2, 1, 1],
                      flexBasis: [D2, D4, D4, D4, D5], // [48%, 30%, 25%, 0%, 20%]
                      justifyContent: [`center`, `flex-end`, null, `flex-end`, `flex-end`],
                      marginTop: [`2rem`, `2rem`, `2.6rem`, `2.4rem`, `2.5rem`]
                    },
                    { // 11 GBV
                      flexGrow: [1, 0, 1, 1, 1],
                      flexBasis: [windowWidth < 350 ? `30%` : D2, `0%`, D4, D4, D5], // [48%, 30%, 25%, null, 16%]
                      justifyContent: [`center`, `flex-start`, `flex-end`],
                      marginTop: [`2rem`, `2.1rem`, `2.6rem`, `2.4rem`, `2.5rem`]
                    },
                    { // 12 Hailstone
                      flexGrow: [1, 2, 2, 0, 8],
                      flexBasis: [D2, `33%`, `0%`, `0%`, D4], // [48%, 30%, 25%, null, 0%]
                      justifyContent: [`center`, `flex-end`, `flex-start`, null, `flex-end`],
                      marginTop: [`2rem`, `2.1rem`, `3.2rem`, `3.2rem`, `2.5rem`]
                    },
                    { // 13 Keychain
                      flexGrow: [1, null, 1, 2, 1],
                      flexBasis: [D2, `28%`, D3, D5, D5], // [48%, 30%, 0%, 25%, 20%]
                      justifyContent: [`center`, `flex-end`],
                      marginTop: [`2rem`, `2.1rem`, `3.2rem`, `3.2rem`, `2.5rem`]
                    },
                    { // 14 Mechanism
                      flexGrow: [1, 0, 1, 2, 0],
                      flexBasis: [D2, D8, D3, D5, `0%`], // [48%, 30%, null, null, 20%]
                      justifyContent: [`center`, `flex-start`, `flex-end`, null, `flex-start`],
                      marginTop: [`2rem`, `2.1rem`, `3.2rem`, `3.2rem`, `3rem`]
                    },
                    { // 15 Muhabbit
                      flexGrow: [1, 2, 0, 1, 1],
                      flexBasis: [D2, D4, `0%`, D6, D6], // [48%, 30%, null, null, 20%]
                      justifyContent: [`center`, `flex-end`, `flex-start`, `flex-end`, `flex-end`],
                      marginTop: [`2.5rem`, `2.1rem`, `3.4rem`, `3.2rem`, `3rem`],
                      'img': {
                        marginRight: [0, null, 0, 0]
                      }
                    },
                    { // 16 TPS
                      flexGrow: [1, 3, 3, 1, 3],
                      flexBasis: [D2, D3, D4, D3, D5], // [48%, 30%, null, null, 20%]
                      justifyContent: [`center`, `flex-end`, `flex-end`, `flex-end`, `flex-end`],
                      marginTop: [`2.5rem`, `2.1rem`, `3.4rem`, `3.5rem`, `3rem`],
                      'img': {
                        marginRight: [0, null, 0, 0]
                      }
                    },
                    { // 17 Valhalla
                      flexGrow: [1, null, 2, 1, 1],
                      flexBasis: [D2, D2, D4, D4, D5], // [48%, 30%, 100%, 30%, 20%]
                      justifyContent: [`center`, `flex-end`, `flex-end`, `center`, `flex-end`],
                      marginTop: [`2.5rem`, `2.5rem`, `3.4rem`, `3.5rem`, `3rem`],
                      'img': {
                        // marginLeft: [0, `4rem`, 0, 0],
                        marginRight: [0, `3.5rem`, 0]
                      }
                    },
                    { // 18 YieldYak
                      flexGrow: [1, null, 2, 1, 2],
                      flexBasis: [D2, D2, D5, D3, D6],
                      justifyContent: [`center`, `flex-start`, `flex-end`, `flex-start`, `flex-end`],
                      marginTop: [`2.5rem`, `2.5rem`, `3.4rem`, `3.5rem`, `3rem`],
                      'img': {
                        marginLeft: [0, `3.5rem`, 0]
                      }
                    }
                  ][index])
                }}
                className={'investor-logo-container'}
              >
                <img
                  sx={{
                    width: ((idx)=>{
                      return [
                        `${width * (idx === 11 ? 0.6 : 0.7)}px`,
                        `${width * 0.78}px`,
                        `${width * 0.9}px`,
                        `${width}px`,
                      ]
                    })(index),
                    height: ((idx)=> {
                      return [
                        `${width * (idx === 11 ? 0.6 : 0.7) * ratio}px`,
                        `${width * 0.78 * ratio}px`,
                        `${width * 0.9 * ratio}px`,
                        `${width * ratio}px`,
                      ]
                    })(index)
                  }}
                  src={url} width={width} height={width * ratio} alt={name} />
              </div>
            )
          })}
        </div>
      </Divider>
    </main>
  )
}

export default Investors 