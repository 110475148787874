/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"

import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import CommunityChannels from "./community-channels"
import FooterContent from "../sections/footer-content"
import FooterContentCopyright from "../sections/footer-content-copyright"

import * as styles from './footer.module.scss' 
import { TEXT_CONTAINER_MARGIN_X } from "../constants/constant"
import FooterMenu from "./footer-menu"
import AppContext from "../context/AppContext"
// import platypusFinanceYellowPaperVDirect from "./platypus-finance-yellow-paper-v-direct.pdf"

const Footer = ({
  offset, factor = 1,
  windowWidth = 0,
  windowHeight = 0,
  menuItems,
}) => {

  return (
    <main style={{
      height: [`auto`, null, `100%`],
      overflow: `auto`
    }}>
      {offset !== undefined ? (
        <Divider key={'footer-community-channels'}
          sx={{
            zIndex: 53,
            overflow: [`visible`,`auto`],
            height: [`auto`, null, `100%`],
            '-webkit-transform': `translateZ(0)`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-between`,
          }}
          speed={windowWidth <= 640 ? 0.15 : 0.3}
          offset={offset + (
          windowWidth > 640 && windowHeight > 830 ?
            0.08 :
            windowWidth <= 640 && windowHeight > 740 ?
              -0.12 :
              windowWidth <= 640 && windowHeight < 590 ?
                -0.06 :
                windowWidth <= 640 && windowHeight < 680 ?
                  -0.055 : 0
          )}
          factor={factor}
        >
          <div>
            <CommunityChannels />
          </div>
          <div sx={{
            padding: [`0rem`, `0 0 3rem`, `0 0 8.25rem`],
            'h5, p:last-child': {
              marginX: TEXT_CONTAINER_MARGIN_X,
            },
            '& > p': {
              fontSize: [`0.778rem`, null, `0.888rem`],
            },
            '& > p:nth-of-type(1)': {
              margin: [`2.2rem 0 0.9rem`, `2.222rem 0 0.986rem`, `2.5rem 0 1.111rem`],
              '& > img:nth-of-type(1)': {
                width: [`4.4445rem`, `5.926rem`, `6.667rem`],
                height: [`5.25675rem`, `7.009rem`, `7.885rem`],
              },
              '& > a:nth-of-type(1) > img, & > img:nth-of-type(2)': {
                width: [`5.926rem`, null, `6.667rem`],
                height: [`2.069rem`, null, `2.327rem`],
              },
              '& > a:hover > img': {
                opacity: 1
              }
            },
            h5: {
              fontSize: [`1rem`, null, `1.125rem`],
              marginTop: [`1.389rem`, `1.611rem`, `1.8125rem`],
            }
          }} className={styles.contentInner}>
            <div className={styles.contentBorderTop} />
            <FooterContent />
            <FooterMenu items={menuItems} />
            <div className={styles.contentCopyright}>
              <FooterContentCopyright />
            </div>
          </div>
        </Divider>
      ) : (
        <div sx={{
          padding: [`0 0 1rem`],
          marginBottom: `3rem`,
          'h5, p:last-child': {
            marginX: TEXT_CONTAINER_MARGIN_X,
          },
          '& > p': {
            fontSize: [`0.778rem`, null, `0.888rem`],
          },
          '& > p:nth-of-type(1)': {
            margin: [`2.2rem 0 0.9rem`, `2.222rem 0 0.986rem`, `2.5rem 0 1.111rem`],
            '& > img:nth-of-type(1)': {
              width: [`4.4445rem`, `5.926rem`, `6.667rem`],
              height: [`5.25675rem`, `7.009rem`, `7.885rem`],
            },
            '& > img:nth-of-type(2)': {
              width: [`5.926rem`, null, `6.667rem`],
              height: [`2.069rem`, null, `2.327rem`],
            }
          },
          h5: {
            fontSize: [`1rem`, null, `1.125rem`],
            marginTop: [`1.389rem`, `1.611rem`, `1.8125rem`],
          }
        }} className={styles.contentInner}>
          <div className={styles.contentBorderTop} />
          <FooterContent />
          <FooterMenu items={menuItems} />
          <div className={styles.contentCopyright}>
            <FooterContentCopyright />
          </div>
        </div>
      )}
    </main>
  )
}

export default Footer