/** @jsx jsx */
import React, { forwardRef, useState } from "react"
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Intro from "@lekoarts/gatsby-theme-cara/src/sections/intro"
import { OutlineButton, SecondaryOutlineButton, NftOutlineButton } from "./button"

import MainVisualDiamond from "./main-visual-diamond.inline.svg"
import { UpDown, UpDownWide, UpDownNarrow } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import {
  mainVisualBg,
  mainVisualContainer,
  mainIntroContainer,
  mainVisualInner,
  mainVisualGraphicInnocent,
  mainVisualGraphicLiquidity,
  mainVisualGraphicNft
} from './main-visual.module.scss'
import commonUtil from "../utils/common-util"

const MainVisual = ({
  offset,
  factor = 1,
  scrollTo = (num) => { console.log('Badge Clicked!! ', num) },
  scrollPos = {}
}, ref) => {
  // console.log('░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░')
  // console.log('░░░░░░░█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░')
  // console.log('░░░█░░░░░░░░░█████████░░████████████░░░█░░')
  // console.log('░░░░░░░░██░░░░░░░░░░░████░░░░░░░░░░████░░░')
  // console.log('░░░░██████░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░')
  // console.log('process.env.LAUNCH_APP_BUTTON => ', process.env.LAUNCH_APP_BUTTON)
  // console.log('process.env.LAUNCH_APP_BUTTON === ""> ', process.env.LAUNCH_APP_BUTTON === '')
  const isShownAppLaunchBtn = commonUtil.isValidLink(process.env.LAUNCH_APP_BUTTON)
  const [nftSceneVisible, setNftSceneVisible] = useState(false);
  const [nftLinkDirty, setNftLinkDirty] = useState(false);

  return (
    <main ref={ref}>
      <Content speed={0.15} offset={offset} factor={factor} sx={{
        variant: `texts.bigger`,
        backgroundSize: [`197% !important`, `145% !important`, null, `132% !important` ,`contain !important`],
        backgroundPosition: [`50% 8%`, null, `50% 50%`],
      }} className={mainVisualContainer} >
        <div sx={{
          backgroundImage: [`url(/bg-platopia-square-20.jpg)`, `url(/bg-platopia-20.jpg)`],
          backgroundSize: [`70vh 70vh`, `contain`],
          backgroundRepeat: `no-repeat`,
          backgroundColor: `#020C16`,
          opacity: nftSceneVisible ? 1 : 0,
          transition: `opacity 0.3s ease-in-out`,
          backgroundPosition: [`50% 0%`, `50% 0%`],
          position: 'absolute',
          top: 0, left: 0,
          width: '100%', height: '100%'
        }}></div>
      </Content>
      <Divider sx={{ zIndex: 51 }} speed={0.2} offset={offset} factor={factor}>
        <UpDownNarrow>
          {/* <MainVisualDiamond sx={{width: [`132vh`,`132vh`,`125vw`,`94vw`], height: [`132vh`,`132vh`,`125vw`,`94vw`], position: `absolute`, top: `50%`, left: `50%`, transform: 'translate(-50%, -50%)'}} /> */}
          <div className={!nftLinkDirty ? mainVisualGraphicInnocent : nftSceneVisible ? mainVisualGraphicNft : mainVisualGraphicLiquidity}>
            <img src="./main-visual.mascot.png" sx={{
              position: `absolute`,
              top: [`50%`, `23%`, `26%`], left: `50%`,
              // transform: ['translate(-50%, -120%)', 'translate(-50%, -50%)'],
              width: [`100.24vw`,`36.2rem`],
              height: [`78.02vw`,`28.114rem`]
            }}/>
            <img src="/graphic-welcome-platopia.png" sx={{
              position: `absolute`,
              top: [`50%`], left: `50%`,
              transform: ['translate(-50%, -120%)', 'translate(-45%, -95%)'],
              width: [`70vw`,`21.375rem`],
              height: [`66vw`,`20.125rem`]
            }}/>
          </div>
        </UpDownNarrow>
      </Divider>
      <Content speed={0.3} offset={offset} factor={factor}
        sx={{
          variant: `texts.bigger`, zIndex: 52, padding: 0
        }}
        className={mainIntroContainer}
      >
        <Inner sx={{
          h1: {
            fontSize: [`13.75vw`, `392%`, `4.445rem`],
            margin: [`2vh 1rem 0`, null, `15vh 1rem 0`]
          },
          h2: {
            fontSize: [`8.2vw`, `267%`, `2.438rem`],
          },
          h4: {
            fontSize: [`5.7vw`, `182%`, `1.875rem`],
            '& ~ div': {
              marginTop: [`4.25vh`, null, `9.15vh`],
              '& > a + a': {
                marginLeft: [`0 !important`, `0.75rem !important`],
                marginTop: [ `0.75rem !important`, `0 !important`]
              },
              '& > a + div': {
                height: [`1vh`],
                '& > span': {
                  fontSize: [`1vh`],
                  lineHeight: [`1vh`],
                }
              }
            },
            '& + div': {
              display: [`none`, `block`],
              '& + div': {
                display: [`block`, `none`],
              },
            },
            br: {
              display: [`block`, null, `none`],
            }
          }
        }} className={mainVisualInner}>
          <Intro />
          <div>
            {isShownAppLaunchBtn === true && (<OutlineButton gTagEvent={{
                event_action: 'Click on Hero',
                event_category: 'Direct to Product (mainnet)',
                event_label: 'Launch App (Beta)'
              }}
              ribbonNote={'Beta'}
              url={"https://app.platypus.finance/"}>Launch App</OutlineButton>)}
            <NftOutlineButton
              gTagEvent={{
                event_action: 'Click on Hero',
                event_category: 'Direct to NFT Landing',
                event_label: 'To Platopia'
              }}
              // {...(
              //   scrollPos.footer !== undefined ?
              //   {
              //     onClick: ()=>scrollTo(...scrollPos.footer)
              //   } : null
              // )}
              url={"https://nft.platypus.finance/"}
              target={'_blank'}
              onMouseEnter={(e)=>{
                setNftSceneVisible(true)
                if (!nftLinkDirty) setNftLinkDirty(true)
              }}
              onMouseLeave={(e)=>{
                setNftSceneVisible(false)
              }}
            >To Platopia</NftOutlineButton>
          </div>
          <div>
            {isShownAppLaunchBtn === true && (<OutlineButton gTagEvent={{
                event_action: 'Click on Hero',
                event_category: 'Direct to Product (mainnet)',
                event_label: 'Launch App (Beta)'
              }}
              ribbonNote={'Beta'}
              size={'small'} url={"https://app.platypus.finance/"}
            >Launch App</OutlineButton>)}
            <div><span>&nbsp;</span></div>
            <NftOutlineButton
              size={'small'}
              gTagEvent={{
                event_action: 'Click on Hero',
                event_category: 'Direct to NFT Landing',
                event_label: 'To Platopia'
              }}
              onMouseEnter={(e)=>{
                setNftSceneVisible(true)
                if (!nftLinkDirty) setNftLinkDirty(true)
              }}
              onMouseLeave={(e)=>{
                setNftSceneVisible(false)
              }}
              url={"https://nft.platypus.finance/"}
              target={'_blank'}
              // {...(
              //   scrollPos.footer !== undefined ?
              //   {
              //     onClick: ()=>scrollTo(scrollPos.footer[0], scrollPos.footer[1] * 0.9)
              //   } : null
              // )}
            >To Platopia</NftOutlineButton>
          </div>
        </Inner>
      </Content>
    </main>
  )
}

export default forwardRef(MainVisual)