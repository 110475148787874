/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import SectionHeader from "./section-header"
import RoadmapGrid from "./roadmap-grid"
import RoadmapCaptions from "../sections/roadmap-captions"
import RoadmapContent from "../sections/roadmap-content"

import { roadmapHeader } from './roadmap.module.scss'

const Roadmap = ({
  offset,
  factor = 1,
  windowWidth,
  windowHeight,
}) => {

  return (
    <main>
      <Content speed={0.15} offset={offset} factor={factor}
        sx={{
          variant: `texts.bigger`,
          padding: `0 !important`,
          justifyContent: [`flex-end !important`],
          zIndex: 53,
          // background: `#05FF3434`,
        }}
      >
        <Inner sx={{
          // overflowX: [`scroll`, null, null, null, `visible`],
          // overflowY: [`visible`],
          // paddingTop: [`35vh`, null, null, null, `17vh`]
          display: `flex`,
          // justifyContent: `flex-end !important`,
          // alignItems: `center`,
          width: [`87.65vw`,`37.8125rem`,`45.7rem`,`59.5625rem`],
          height: [`118.58vw`, `51.158rem`],
          // marginTop: [`35vh`, null, null, `0`],
          marginBottom: [
            windowHeight < 590 ? `2.4rem`: windowHeight < 740 ? 0 : `5vh`,
            0
          ]
        }}>
          {/* <RoadmapGrid windowWidth={windowWidth} >
            <RoadmapContent />
          </RoadmapGrid> */}
          <RoadmapGrid windowWidth={windowWidth} />
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={factor} sx={{zIndex: 53}} sx={{
        pointerEvents: `none`,
        boxSizing: `border-box`,
        width: [`100vw !important`, null, null, `15.125rem !important`],
        height: [
          `calc(${ windowHeight < 590 ? `90vh` : windowHeight < 680 ? `97vh` : windowHeight < 740 ? `90vh` : `98vh` } - 114.876vw) !important`,
          `calc(100vh - 43.4375rem) !important`,
          null,
          `${ windowHeight * factor }px !important`
        ],
        marginLeft: [0, null, null, `calc((100vw - 59.5625rem) / 2)`],
        paddingTop: [0, null, null, `calc(${ windowHeight * factor }px - 43.4375rem)`],
        display: `flex`,
        justifyContent: [
          windowHeight < 740 ? `flex-end` : `center`,
          `flex-end`,
          null,
          `center`
        ],
        flexDirection: `column`,
        h2: {
          marginTop: 0,
          marginBottom: [0, `8%`, `5%`],
          marginX: [`1rem`, 0],
          textAlign: [`center`, null, null, `left`],
          fontSize: [`283%`, null, `3.44rem`]
        }
      }} className={roadmapHeader}>
        <RoadmapCaptions />
      </Divider>
    </main>
  )
  
}

export default Roadmap