/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import GetOutboundLink from "./get-outbound-link"
import HoverList from './hover-list'
import * as styles from './footer-menu.module.scss'
import commonUtil from "../utils/common-util"
import AppContext from "../context/AppContext"

const FooterMenu = ({
  items = [{
    label: 'Nothing to click yet...'
  }]
}) => {
  console.log('%%%O%%%')
  console.log('FooterMenu -> items : ', items)

  const {
    defineViewability
  } = React.useContext(AppContext);

  const locateItemEventAction = (mItem) => {

    const {
      eventProps,
      ...mItemProps
    } = mItem

    const locatedEventProps = eventProps ?
      {
        ...eventProps,
        event_action: eventProps.event_action.replace('{location}', 'Header')
      } : null

    return {
      ...(eventProps && locatedEventProps !== null ?
        {
          eventProps: locatedEventProps
        } :
        null
      ),
      ...mItemProps
    }
  }

  const localiseMenuItem = (mItem) => {

    const { items, ...mItemProps } = mItem
    
    const locatedItems = items !== undefined ?
      [...items.map((subMItem) => locateItemEventAction(subMItem))] :
      null
    
    return {
      ...locateItemEventAction(mItemProps),
      ...(items && locatedItems !== null ? {
        items: locatedItems
      } : null)
    }
  }
  
  const menuItems = items.map((mItem) => localiseMenuItem(mItem))

  console.log('FooterMenu -> menuItems : ', menuItems)

  const footerMenuItemGen = ({eventProps, href, target, onClick, label}) => {
    /*
      label: 'Docs',
      href: '/404',
      target: '_self',
      eventProps: {...}
    */
    return (
      <GetOutboundLink
        {...(eventProps ?
          {
            eventProps: eventProps
          } : null
        )}
        {...(href ?
          {
            href: href
          } : null
        )}
        {...(target ?
          {
            target: target
          } : null
        )}
        {...(onClick ?
          {
            funcOnClick: onClick
          } : null
        )}
        sx={{
          fontSize: [`1rem !important`, null, `1.25rem !important`],
        }}
        className={'footer-link'}
      >{label}</GetOutboundLink>
    )
  }
  const footerMenuItemListGen = ({caption, additionalClassName, list}) => {
    return (
      <HoverList
        upward={true}
        {...{
          ...(caption ? {caption: caption}: null),
          ...(additionalClassName ? {additionalClassName: additionalClassName}: null),
          ...(list ? {list: list}: null)
        }}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div sx={{
        justifyContent: `center`,
        flexWrap: [`wrap`, null, `nowrap`],
        marginTop: [`-1rem`, 0],
        paddingRight: `0 !important`,
        'a, .tag-link': {
          flexBasis: [`30%`, `23%`, `auto`],
          marginTop: [`1rem`, 0],
          '& + a, & + .tag-link': {
            paddingLeft: [`0 !important`, `1rem !important`],
            marginLeft: [`0 !important`, `1rem !important`],
          }
        }
      }} className='tag-links'>
        {items && menuItems && menuItems.map((item, index) => {
          return item.viewability !== undefined &&
            defineViewability !== undefined &&
            typeof defineViewability === 'function' &&
            defineViewability(item.viewability,1) ?
              item.items && item.items.length > 0 ?
              footerMenuItemListGen({
                caption: item.label,
                additionalClassName: 'tag-link footer-link',
                list: item.items
              }) :
              footerMenuItemGen({ ...item })
            : null;
        })}
      </div>
    </div>
  )
}

export default FooterMenu