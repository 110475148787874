/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import {
  gridBackground,
  gridGraphic
} from './roadmap-grid.module.scss' 

const RoadmapGrid = ({
  children,
  windowWidth,
  ...props
}) => {

  return (
    <div
      sx={{
        width: `100%`,
        height: `100%`,
        backgroundImage: [
          windowWidth < 420 ?
          `url("./roadmap-grid.graphic.svg")` :
          `url("./roadmap-grid.graphic-420.svg")`,
          null,
          `url("./roadmap-grid.graphic-640.svg")`
        ],
        backgroundPosition: [`50% 0%`, null, null, `100% 50%`],
        marginX: [0, `1rem`, 0]
      }}
      className={gridGraphic}
    ></div>
  )
  
  // return (
  //   <div sx={{
  //     display: `grid`,
  //     gridAutoFlow: `row`,
  //     // width: [`100vw`,`37.8125rem`,`45.75rem`,`59.5625rem`],
  //     width: `100%`,
  //     height: `100%`,
  //     gridTemplateColumns: [
  //       '20.83% 20.99% 20.66% 20.99% 16.53%',
  //       null,
  //       '17.21% 17.35% 17.08% 17.35% 13.66% 17.35%',
  //       '23.19% 13.22% 13.33% 13.12% 13.33% 10.49% 13.33%'
  //     ],
  //     gridTemplateRows: [
  //       '8.35% 21.29% 19.42% 16.26% 16.98% 17.70%'
  //     ],
  //     p: {
  //       margin: `0`,
  //       fontSize: [`${ windowWidth < 360 ? `0.764` : windowWidth < 400 ? `0.833` : `0.944` }rem`, `1.375rem`],
  //       fontWeight: 500,
  //       // padding: [`1.406rem 1.125rem 1.078rem`, null, null, `1.875rem 2.25rem 1.4375rem`],
  //     },
  //     // width: [`63rem`, `74rem`, null, null, `auto`],
  //     // padding: [`0 1.5rem 0 1.5rem`, null, null, null, `0`]
  //     backgroundPosition: [`0% 50%`, null, null,`63.51% 50%`],
  //     '& > *:nth-child(1), & > *:nth-child(6)':{
  //       p: {
  //         fontSize: [`0.833rem`, `1rem`]
  //       }
  //     },
  //     '& > *:nth-child(1)': {
  //       gridColumn: [`1 / 2`, null, null, `2 / 3`]
  //     },
  //     '& > *:nth-child(2)': {
  //       gridColumn: [`2 / 3`, null, null, `3 / 4`],
  //       gridRow: [`2 / 3`],
  //     },
  //     '& > *:nth-child(3)': {
  //       gridColumn: [`3 / 4`, null, null, `4 / 5`],
  //       gridRow: [`2 / 3`],
  //     },
  //     '& > *:nth-child(4)': {
  //       gridColumn: [`4 / 5`, null, null, `5 / 6`],
  //       gridRow: [`2 / 3`],
  //     },
  //     '& > *:nth-child(5)': {
  //       gridColumn: [`1 / 4`, null, `1 / 5`, `2 / 6`],
  //       gridRow: [`3 / 5`],
  //       p: {
  //         fontSize: [`0.778rem`, `1.25rem`],
  //       }
  //     },
  //     '& > *:nth-child(6)': {
  //       gridColumn: [`-2 / -3`, null, `-1 / -2`],
  //       gridRow: [`3 / 4`],
  //       p: {
  //         textAlign: [`right`, null, `left`],
  //         marginLeft: [0],
  //         marginRight: [`-0.75rem`, `-1.25rem`, `1rem`],
  //       }
  //     },
  //     '& > *:nth-child(7)': {
  //       gridColumn: [`-2 / -3`, null, `-1 / -2`],
  //       gridRow: [`4 / 5`],
  //       p: {
  //         textAlign: [`right`, null, `left`],
  //         marginRight: [`-0.75rem`, `-1.25rem`, 0],
  //       }
  //     },
  //     '& > *:nth-child(8)': {
  //       gridColumn: [`2 / 3`, null, null, `3 / 4`],
  //       gridRow: [`-2 / -3`],
  //     },
  //     '& > *:nth-child(9)': {
  //       gridColumn: [`3 / 4`, null, null, `4 / 5`],
  //       gridRow: [`-2 / -3`],
  //     },
  //     '& > *:nth-child(10)': {
  //       gridColumn: [`4 / 5`, null, null, `5 / 6`],
  //       gridRow: [`-2 / -3`],
  //     },
  //     '& > *:nth-child(11)': {
  //       gridColumn: [`1 / 4`, null, null, `2 / 5`],
  //       gridRow: [`-1 / -2`],
  //     },
  //   }} className={gridBackground}>
  //     {children}
  //   </div>
  // )
}

export default RoadmapGrid