export const CONTENT_CONTAINER_WIDTH = [
  `calc(100vw - 1.25rem)`,
  `35rem`, // 630
  `50rem`, // 800
  `60rem`, // 960
  `72.5rem` // 1160
]

export const TEXT_CONTAINER_WIDTH = [`0 1.625rem`, 0]
export const SECTION_HEADER_CONTAINER_MARGIN = [`0 1.625rem`, 0]
export const TEXT_CONTAINER_MARGIN_X = [`1.625rem`, 0]

export const FONT_SIZES = {
  subtitle: [`1.444rem`, null, `1.875rem`, null, `2.0625rem`],
  subtitle2: [`1.225rem`, null, `1.482rem`, null, `1.755rem`],
  subtitle2Sm: [null, `1.225rem`, `1.482rem`, null, `1.755rem`],
  p: [`1rem`, null, `1.25rem`, null, `1.375rem`],
  pSm: [`0.75rem`, `1rem`, `1.25rem`, null, `1.375rem`],
  note: [`0.75rem`, null, `1rem`, null, `1.2rem`],
}

export const COLORS_HEX = {
  background: '#040C15',
  text: '#FFFFFF',
  primary: '#00F152',
  support: '#AEFCE1'
}

export const COLORS_RGB = {
  background: `4,12,21`,
}