import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Title of article",
  "attachments": ["./platypus-finance-yellow-paper-v20211131.pdf"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/logo-platypus-v2.png",
        "alt": "Platypus"
      }}></img>{` `}<a href="https://bridge.avax.network/login" target="_blank"><img src="/logo-powered-by-avalanche.inline.svg" title="Powered by Avalanche" /></a></p>
    <h5>{`First of its kind AMM on Avalanche with open-pool liquidity`}</h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      