/** @jsx jsx */
import React from "react"
import styled from "styled-components";
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import SectionHeader from "./section-header"
import SolutionCaptions from "../sections/solution-captions"
import Mechanism from "../sections/mechanism"
import Benefits from "../sections/benefits"
// import SolutionBadge from "../components/solution-badge"
// import SolutionsTitle from "../sections/solutions-title"
// import SolutionsContentAmm from "../sections/solutions-content-amm"
// import SolutionsContentStablecoin from "../sections/solutions-content-stablecoin"
// import SolutionsContentLending from "../sections/solutions-content-lending"

import { UpDown, UpDownNarrow } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import {
  solutionsBg,
  solutionsStatement,
  solutionsStatementInner,
  solutionsTitle,
  solutionsContent,
  // mechanismContainer,
  mechanismContent
} from './solutions.module.scss' 
import { CONTENT_CONTAINER_WIDTH, FONT_SIZES, COLORS_RGB, SECTION_HEADER_CONTAINER_MARGIN } from "../constants/constant"
import theme from "../gatsby-plugin-theme-ui";

const Solutions = React.forwardRef(({
  offset,
  factor = 1,
  scrollTo = (num) => { console.log('Badge Clicked!! ', num) },
  windowWidth,
  windowHeight,
  captionTopPosOffset,
  captionOpacity,
}, ref) => {
  
  const { colors } = theme

  return (
    <main>
      <Content sx={{
        variant: `texts.bigger`,
        zIndex: 52,
        backgroundSize: [`280% !important`, `200% !important`, null,`contain !important`],
        backgroundPosition: [`50% 15%`, `50% 50%`, `50% 50%`, `50% 65%`, `50% 50%`]
      }} speed={0.25} offset={offset} factor={factor}
        className={solutionsStatement}
      >
        <Inner sx={{
          textAlign: `left`,
          width: [`calc(100vw - 1.5rem) !important`, `30.556rem !important`, `34.375rem !important`],
          paddingTop: [`2.25rem`, `4.3rem`],
          paddingBottom: [`1.756rem`, `3.875rem`],
          marginTop: [`23vh`, `12vh`]
        }}>
          {/* <div sx={{
            width: [`calc(100vw - 5rem)`, `18.333rem`, `20.563rem`],
            marginX: `auto`,
            h6: {
              fontSize: [`1.111rem`, `1.375rem`],
              '& + h5': {
                marginTop: [`0.5rem`, `1rem`]
              }
            },
            h5: {
              fontSize: [`1.625rem`],
              marginX: [`-0.75rem !important`, `-2rem !important`],
              '& + p': {
                marginTop: [`0.5rem`, `1rem`]
              }
            },
            p: {
              fontSize: [`1rem`, `1.25rem`]
            }
          }} className={mechanismContent}>
            <Mechanism />
          </div> */}
        </Inner>
      </Content>
      <Divider speed={0.04} offset={offset + 0.05} factor={factor * 0.5} sx={{zIndex: 53}}>
        <SectionHeader
          height={[`${ windowHeight < 740 ? `24` : `14` }vh`, `24vh`, null, null, `24vh`]}
          headerMargin={SECTION_HEADER_CONTAINER_MARGIN}
          // justifyContent={`flex-start`}
        >
          <SolutionCaptions />
        </SectionHeader>
        {/* <div ref={ref} sx={{
          width: [`100vw`, `29rem`, `32.625rem`],
          height: [`152.644vw`, `37.442rem`, `42.123rem`],
          marginX: [`auto`],
        }}>
          <AnimatedDiv topOffset={captionTopPosOffset} opacity={captionOpacity}>
            <div sx={{
              width: [`100%`],
              height: [`100%`],
              backgroundImage: `url('./solution-graphic-diamond-new-light.png')`,
              backgroundSize: [`120vw 152.644vw`, `cover`],
              backgroundPosition: [`50% 50%`],
              backgroundRepeat: `no-repeat`,
            }}>
              <div sx={{
                width: `100%`, height: [`80%`, `88.4%`],
              }}>
                <SectionHeader
                  width={`100%`} height={`100%`}
                  justifyContent={`center`}
                  headerMargin={[`0 13.05%`]}
                  subHeaderMargin={[`0.722rem 5% 0`]}
                  headerFontSize={[`10vw`, `3.445rem`]}
                >
                  <SolutionCaptions />
                </SectionHeader>
              </div>
            </div>
          </AnimatedDiv>
        </div> */}
      </Divider>
      <Divider
        speed={windowWidth <= 640 ? 0.15 : 0.3} offset={offset + 0.18} factor={factor - 0.6}
        sx={{
          zIndex: 54,
          'h6, p': {
            margin: 0,
          },
          h6: {
            fontSize: FONT_SIZES.subtitle,
            fontWeight: 400,
            color: colors.support,
          },
          p: {
            fontSize: FONT_SIZES.p,
            color: `rgba(255, 255, 255, 0.7)`,
            b: {
              color: `rgba(255, 255, 255, 1)`,
            }
          },
        }}
      >
        <div sx={{
          background: [
            `linear-gradient(0deg, rgba(${COLORS_RGB.background},1) 50%, rgba(${COLORS_RGB.background},0) 100%)`,
            `transparent`
          ]
        }}>
          <div sx={{
            width: CONTENT_CONTAINER_WIDTH,
            marginX: [`auto`],
            'h6, h5': {
              textAlign: `center`,
            },
            h6: {
              '& + h5': {
                margin: [`1.313rem 0 0`]
              }
            },
            h5: {
              fontSize: [`2rem`, `2.813rem`],
              fontWeight: 600,
              color: `#01F153`
            },
            p: {
              '& + p': {
                marginTop: [`1rem`]
              },
            },
            '.paragraph': {
              columnCount: [1, 2],
              WebkitColumnGap: [0, `2rem`, `3rem`, `4.5rem`, `5rem`],
              MozColumnGap: [0, `2rem`, `3rem`, `4.5rem`, `5rem`],
              columnGap: [0, `2rem`, `3rem`, `4.5rem`, `5rem`],
              columnFill: `balance`,
              marginTop: [
                windowHeight < 680 ?
                  `35vw` : windowHeight < 740 ? `31vw` : `16vw`,
                `3.3rem`
              ],
              marginX: [`auto`],
              // paddingTop: [`45vw`, 0],
              width: [`calc(100% - 2rem)`, `auto`],
              height: [`auto`, `8.75rem`, `8.4375rem`]
            }
          }}>
            <Mechanism />
          </div>
        </div>
        <div sx={{
          backgroundColor: [colors.background, `transparent`],
        }}>
          <GradientDiv bgColor={{
            hexCode: colors.background,
            rgb: COLORS_RGB.background,
          }}>
            <div sx={{
              display: `flex`,
              flexDirection: [`column`, `row`],
              justifyContent: [`space-between`],
              width: CONTENT_CONTAINER_WIDTH,
              marginX: `auto`,
              paddingTop: [`2.5rem`, `0.75rem`],
              paddingBottom: [`2rem`],
              '& > .column': {
                width: [`100%`, `30.53%`],
                '& > *': {
                  marginX: `auto`,
                },
                '& > img': {
                  width: [`82.76%`],
                  display: `block`,
                  '& + h6': {
                    marginTop: `1.6875rem`,
                    '& + p': {
                      marginTop: `0.875rem`
                    }
                  },
                  '& + h6, & + h6 + p': {
                    width: [`calc(100% - 2rem)`, `auto`],
                  }
                },
                '& + .column': {
                  marginTop: [ `1.935rem`, 0]
                }
              }
            }}>
              <Benefits />
            </div>
          </GradientDiv>
        </div>
      </Divider>
      {/* <Divider speed={0.3} offset={offset} factor={factor} sx={{
        zIndex: 53,
        width: [`100vw !important`, null, `calc((100vw - 34.375rem) / 2) !important`],
      }}>
        <UpDown>
          <Inner sx={{
            width: `100%`,
            position: `relative`, height: `100vh`
          }}>
            <img src="./mechanism-graphic-liquidity.png" sx={{
              width: [`calc((100vw - 3.333rem) * 0.52)`, null, `25vw`, `12.25rem`],
              position: `absolute`,
              right: [null, null, `2.15rem`, `7.1875rem`],
              top: `28%`,
            }} />
          </Inner>
        </UpDown>
      </Divider>
      <Divider speed={0.4} offset={offset} factor={factor} sx={{
        zIndex: 53,
        width: [`100vw !important`, null, `calc((100vw - 34.375rem) / 2) !important`],
        left: `auto !important`
      }}>
        <UpDown delay={`1s`} duration={`5s`}>
          <Inner sx={{
            width: `100%`,
            position: `relative`, height: `100vh`
          }}>
            <img src="./mechanism-graphic-liability.png" sx={{
              width: [`calc((100vw - 3.333rem) * 0.52)`, null, `29.26vw`, `14.337rem`],
              position: `absolute`,
              left: [null, null, `calc(2.15rem - 4.26vw)`, `5.1rem`],
              top: `40%`,
            }} />
          </Inner>
        </UpDown>
      </Divider> */}
    </main>
  )
})

const AnimatedDiv = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  transform: translateY(${({topOffset})=>(topOffset ? topOffset : 0)}px);
  opacity: ${({opacity}) => opacity !== undefined && opacity !== null ? opacity : 1};
`

const GradientDiv = styled.div`
  background: ${({ bgColor: { hexcode } }) => (hexcode !== undefined ? hexcode : '#000000')};
  background: linear-gradient(0deg, rgba(${({ bgColor: { rgb } }) => (rgb !== undefined ? rgb : '0,0,0')},1) 50%, rgba(${({ bgColor: { rgb } }) => (rgb !== undefined ? rgb : '0,0,0')},0) 100%);
`

export default Solutions