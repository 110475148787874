import { useState, useEffect, useRef, useContext } from 'react';
import AppContext from '../context/AppContext';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = typeof window !== "undefined" ? window : {
    innerWidth: 0, innerHeight: 0
  };
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const {
    getState,
    showLoading,
    setLoadingMessage
  } = useContext(AppContext);
  let timeoutOwner;
  let timerActive = useRef(false);

  useEffect(() => {
    function handleResize() {
      // console.log('////  ^ u ^  ////')
      // console.log('recalculationOnResize : ', recalculationOnResize)
      // console.log('getState("recalculationOnResize") : ', getState('recalculationOnResize'))
      setWindowDimensions(getWindowDimensions());
      
      // Switched off a layout recalculation logic
      // if (timerActive.current === false) {
      //   if (getState('recalculationOnResize') === true) {
      //     timerActive.current = true;
      //     setLoadingMessage('Page layout will be recalculated...');
      //     showLoading();
      //   }
      // } else {
      //   clearTimeout(timeoutOwner);
      // }
      // if (getState('recalculationOnResize') === true) {
      //   timeoutOwner = setTimeout(function() {
      //     timerActive.current = false;
      //     window.location.reload();
      //   }, 1000);
      // }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}