/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"

const ComparisonGrid = ({
  keyPrefix = 'grid-A',
  data = [
    {
      stub: true,
      rows: [
        { isHeader: true },
        { isHeader: false, text: 'Stub Row 2' },
      ]
    },
    {
      rows: [
        { isHeader: true, text: 'Head Row Col 1' },
        { isHeader: false, text: 'Row 2 Col 1' },
      ]
    }
  ],
  rowsTemplateGroup,
  windowWidth
}) => {
  const colLen = data.length
  const rowLen = data.reduce((prev, curr) => {
    const currRowLen = curr.rows !== undefined && curr.rows.length !== undefined ? curr.rows.length : 0;
    return prev < currRowLen ? currRowLen : prev;
  }, 0)

  const dataStub = windowWidth <= 640 ? [] : data.filter((itm) => itm.isStub)
  const dataContent = windowWidth <= 640 ? data : data.filter((itm) => !itm.isStub)

  const templateGen = (_len, phrase) => {

    let result = ''
    const len = Array.isArray(phrase) ? phrase.length > _len ? phrase.length : _len : _len
    
    // console.log('len : ', len)

    const phraseGen = (i, prevPhrase) => {
      // console.log('prevPhrase : ', prevPhrase)
      // console.log(`arg : `, arg)
      // console.log(`len : `, len)
      // console.log(`arg[${i}] : `, arg[i])
      const currPhrase = Array.isArray(phrase) ? phrase[i] : phrase
      return prevPhrase === '' ? currPhrase : prevPhrase + ' ' + currPhrase
    }

    for (var i = 0; i < len; i++) result = phraseGen(i, result)

    // console.log('result : ', result)

    return result
  }

  const templateGroupGen = (grp) => {
    return grp.map((itm, idx) => {
      return itm === null ? itm : templateGen(rowLen, itm)
    })
  }

  const gridTemplate = {
    cols: [
      `${templateGen(colLen - 1, '1fr')}`,
      `calc(31vw - 1.5rem) ${templateGen(colLen - 1, '1fr')}`,
      `calc(25vw - 1.5rem) ${templateGen(colLen - 1, '1fr')}`,
      null,
      templateGen(colLen, '1fr')
    ],
    rows: templateGroupGen(rowsTemplateGroup)
  }

  const gridItemsRenderer = (_data) => {
    return _data.map(({
      isStub = false,
      color,
      rows,
      colSpan: srcColSpan,
      rowAutoCount,
      rowAutoCountOffset = 0,
    }, colIdx)=> {
      return rows.map(({
        isHeader = false,
        text = '--',
        rowSpan,
        colSpan,
        display,
        fontSize,
        fontWeight,
        textTransform,
        alignSelf,
        padding,
        borderBottom,
        borderRight,
        after,
      }, rowIdx) => {
        return (
          <div
            key={keyPrefix + '-' + colIdx + '-' + rowIdx}
            sx={{
              ...(borderBottom !== undefined ? {
                borderBottom: borderBottom
              } : rowIdx < (rows.length - 1) ? {
                borderBottom: isHeader ? `2px solid #FFFFFF` : `1px solid #686D73`
              } : isStub ? {
                borderBottom: [`1px solid #686D73`, `0 none`]
              } : null),
              ...(borderRight !== undefined ? {
                borderRight: borderRight
              } : isStub ? {
                borderRight: [`0 none`, `2px solid #FFFFFF`]
              } : null),
              ...(rowSpan !== undefined ? {
                gridRow: rowSpan
              } : rowAutoCount ? {
                gridRow: `${rowIdx + 1 + rowAutoCountOffset} / ${rowIdx + 2 + rowAutoCountOffset}`
              } : null),
              ...(colSpan !== undefined ? {
                gridColumn: colSpan
              } : srcColSpan !== undefined ? {
                gridColumn: srcColSpan
              } : null),
              ...(alignSelf !== undefined ? {
                display: `flex`,
                alignItems: alignSelf
              } : null),
              ...(display !== undefined && alignSelf === undefined ? {
                display: display
              } : null),
              ...(after !== undefined ? {
                '&:after': after
              } : null),
              ...(isStub ? {
                justifyContent: [`center`, `flex-start`],
                borderTop: [`1px solid #FFFFFF`, `0 none`]
              } : null),
            }}
          >
            <p sx={{
              opacity: text === '' || text === ' ' || text === '--' ? 0 : isHeader || isStub ? 1 : 0.7,
              fontSize: fontSize !== undefined ? fontSize : isHeader || isStub ? [`0.944rem`, `1.111rem`, `1.375rem`] : [`1rem`, null, `1.25rem`],
              wordWrap: `break-word`,
              color: color !== undefined ? color : isStub ? `#BFFAE2` : `#FFFFFF`,
              fontWeight: fontWeight !== undefined ? fontWeight : isStub ? 500 : isHeader ? 600 : 400,
              ...(textTransform !== undefined ? {
                textTransform: textTransform
              }: null),
              ...(padding !== undefined ? {
                padding: `${padding} !important`
              } : isHeader ? {
                paddingTop: [`1.3125rem !important`],
                paddingBottom: [`1rem !important`]
              } : null),
              ...(isStub ? {
                textAlign: [null, `left !important`],
              } : null),
            }}>{text}</p>
          </div>
        )
      })
    })
  }

  return (
    <div sx={{
      display: `grid`,
      gridAutoFlow: `column`,
      gridTemplateRows: gridTemplate.rows,
      gridTemplateColumns: gridTemplate.cols,
      p: {
        margin: `0`,
        padding: [`1.2rem 0.833rem 1.078rem`, `1.335rem 1.125rem 1.078rem`, null, `1.83rem 2.25rem 1.4375rem`],
        textAlign: `center`,
        // padding: [`1.406rem 1.125rem 1.078rem`, null, null, `1.875rem 2.25rem 1.4375rem`],
      },
      width: [`calc(100vw - 0.5rem)`, `calc(100vw - 3rem)`, null, null, `auto`],
      padding: [
        `0 0.25rem 0`,
        `0 1.5rem 0`,
        null,
        null,
        `0`
      ]
    }}>
      {dataStub.length > 0 && (<div sx={{
        gridColumn: `1 / 2`,
        gridRow: `1 / 9`,
        display: `grid`,
        gridTemplateColumns: [
          `calc(36vw - 0.25rem)`,
          `calc(31vw - 1.5rem)`,
          `calc(25vw - 1.5rem)`,
          null,
          `1fr`
        ],
        gridTemplateRows: gridTemplate.rows,
        width: [
          `calc(36vw - 0.25rem)`,
          `calc(31vw - 1.5rem)`,
          `calc(25vw - 1.5rem)`,
          null,
          `auto`
        ]
      }}>{gridItemsRenderer(dataStub)}</div>)}
      <div sx={{
        gridColumn: [`1 / 3`, `2 / 6`],
        gridRow: [`1 / 14`, `1 / 9`],
        display: `grid`,
        gridTemplateColumns: [
          templateGen(colLen - 1, 'calc(50vw - 0.25rem)'),
          templateGen(colLen - 1, '31vw'),
          templateGen(colLen - 1, '38vw'),
          templateGen(colLen - 1, '1fr')
        ],
        gridTemplateRows: gridTemplate.rows,
        width: [
          // `calc(64vw - 0.25rem)`,
          `auto`,
          `calc(69vw - 1.5rem)`,
          `calc(75vw - 1.5rem)`,
          null,
          `auto`
        ],
        overflowX: [`scroll`],
        overflowY: [`hidden`],
        '&::-webkit-scrollbar': {
          display: `none`
        }
        // backgroundColor: `blue`
      }}>{gridItemsRenderer(dataContent)}</div>
      {/* {gridItemsRenderer(dataStub)} */}
    </div>
  )
}

export default ComparisonGrid