/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import AuditsContent from "../sections/audits-content"
import GetOutboundLink from "./get-outbound-link"

import * as styles from './audits.module.scss'
import { CONTENT_CONTAINER_WIDTH, SECTION_HEADER_CONTAINER_MARGIN } from "../constants/constant"
import SectionHeader from "./section-header"

const Audits = ({
  offset, factor = 1,
  windowWidth,
  windowHeight,
}) => {
  // console.log("Window Height >> ", windowHeight)
  const { allFile: { edges: data }, allMdx: { edges: dimensions } } = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "png" } }, sort: {fields: name, order: ASC}) {
        edges {
          node {
            publicURL
            name
            dir
          }
        }
      }
      allMdx(filter: {slug: {eq: "audits-content"}}) {
        edges {
          node {
            id
            frontmatter {
              dimensions
              ratios
            }
            slug
          }
        }
      }
    }
  `)
  console.log('░░░░░██░░░██░░░██░░░██░░░██░░░██░░░██░░░██░░░██░░░░░')
  console.log('data')
  console.log(data)
  console.log('dimensions')
  console.log(dimensions)
  const imgAudits = data
    .filter(({ node: { dir, name }})=> {
      console.log('dir', dir)
      return dir.indexOf('investors') >= 0 && name.indexOf('auditor') >= 0
    })
    .map(({ node: { publicURL, name }}) => {
      return {
        url: publicURL,
        name: name+'.png'
      }
    })
  const { node: { frontmatter: { dimensions: imgDimensions, ratios: imgRatios } } } = dimensions[0]
  const auditsLen = Math.min(imgAudits.length, imgDimensions.length, imgRatios.length)
  const dataAudits = imgAudits
    .filter((imgData, index)=>index < auditsLen)
    .map(({ url, name }, i) => {
      return {
        url: url,
        name: name,
        width: imgDimensions[i],
        ratio: imgRatios[i]
      }
    })
  // console.log('$$%%$$%%$$')
  // console.log('$$$%%%%$$$')
  // console.log('$$$$%%$$$$')
  // console.log('$$ data $$')
  // console.log(data)
  // console.log('$$ investors $$')
  // console.log(dataInvestors)
  // console.table(dataInvestors)
  // console.log('$$ dimensions $$')
  // console.log(dimensions)
  // console.log('$$ imgDimensions $$')
  // console.log(imgDimensions)
  // console.log('$$ imgRatios $$')
  // console.log(imgRatios)

  const D1 = `88%`,
    D2 = `48%`,
    D3 = `30%`,
    D4 = `25%`,
    D5 = `19%`,
    D6 = `15%`,
    D7 = `13.5%`,
    D8 = `10%`,
    D9 = `8.5%`,
    D10 = `6%`

  return (
    <main>
      <Divider
        sx={{
          zIndex: 53,
          // background: `#FF053434`,
          paddingTop: [
            windowHeight < 680 ?
            `0.25rem` : `3.25rem`,
            null,
            0
          ],
        }}
        speed={0.12}
        offset={offset + (
          windowWidth <= 640 ?
            windowHeight < 740 ?
              windowHeight < 680 ?
                windowHeight < 590 ?
                  factor * -0.07 :
                0 : // < 680
              factor * -0.07 : // < 740
            0 // >= 740
          : 0
        )}
        // offset={offset + (factor * 0.08)}
        factor={factor}
      >
        <SectionHeader
          height={[
            `${
              windowHeight < 590 ?
                `13` :
                windowHeight < 740 ?
                  `16` :
                  `14`
            }vh`,
            `16vh`,
            null,
            null,
            windowHeight < 830 ? `7rem` : `6.5rem`
          ]}
          headerMargin={SECTION_HEADER_CONTAINER_MARGIN}
          paddingTop={[
            windowHeight < 590 ?
              `0rem` :
              `1rem`,
            null,
            windowHeight < 830 ? `2rem` : `2rem`,
            windowHeight < 830 ? `2.2rem` : `2.2rem`,
            windowHeight < 830 ? `0.5rem` : `4rem`
          ]}
          justifyContent={[`flex-start`]}
        >
          <AuditsContent />
        </SectionHeader>
        <div sx={{
          width: CONTENT_CONTAINER_WIDTH,
          paddingTop: [0,null,null,windowHeight < 830 ? `1rem`:`4rem`]
          // paddingTop: `${windowHeight * factor * 0.5}px`
          // '.investor-logo-container': {
          //   '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10)': {
          //     // backgroundColor: '#FD564956',
          //     flexGrow: 1,
          //   },
          //   '&:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)': {
          //     flexBasis: `18%`
          //   },
          //   '&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10)': {
          //     flexBasis: `21%`
          //   },
          // }
        }} className={styles.investorsContainer}>
          {dataAudits.map(({url, name, width, ratio}, index)=>{
            return (
              <GetOutboundLink key={'auditor-logo-container-' + index}
                href={'https://medium.com/platypus-finance/platypus-successfully-passed-hackens-smart-contract-audit-and-omniscia-s-security-analysis-767d79bff5a6'}
                target='_blank'
                eventProps={{
                  event_action: 'Click on Landing Content',
                  event_category: 'Post Reading',
                  event_label: 'Medium post about audits'
                }}
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  // height: [`3.5rem`, `auto`],

                  'img': {
                    display: `block`,
                    // margin: `0 auto`
                  },
                  ...([
                    { // 1 Hacken
                      flexGrow: [1],
                      flexBasis: [D2],
                      justifyContent: [`center`, null, `flex-end`],
                      marginTop: [0],
                      'img': {
                        marginRight: [0, null, `3rem`]
                      }
                    },
                    { // 2 Omniscia
                      flexGrow: [1],
                      flexBasis: [D2],
                      justifyContent: [`center`, null, `flex-start`],
                      marginTop: [0],
                      'img': {
                        marginLeft: [0, null, `2rem`]
                      }
                    }
                  ][index])
                }}
                className={'investor-logo-container'}
              >
                <img
                  sx={{
                    width: ((idx)=>{
                      return [
                        `${width * (idx === 11 ? 0.6 : 0.7)}px`,
                        `${width * 0.78}px`,
                        `${width * 0.9}px`,
                        `${width}px`,
                      ]
                    })(index),
                    height: ((idx)=> {
                      return [
                        `${width * (idx === 11 ? 0.6 : 0.7) * ratio}px`,
                        `${width * 0.78 * ratio}px`,
                        `${width * 0.9 * ratio}px`,
                        `${width * ratio}px`,
                      ]
                    })(index)
                  }}
                  src={url} width={width} height={width * ratio} alt={name} />
              </GetOutboundLink>
            )
          })}
        </div>
      </Divider>
    </main>
  )
}

export default Audits 