import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Investors",
  "dimensions": [372, 245, 61, 81, 108, 170, 94, 149, 154, 168, 157, 232, 168, 174, 108, 216, 178, 147],
  "ratios": [0.169354838709677, 0.338775510204082, 1.63885245901639, 1, 0.803703703703704, 0.307176470588235, 1.14553191489362, 0.263154362416107, 0.376623376623377, 0.18452380952381, 0.496815286624204, 0.413479052823315, 0.339285714285714, 0.384597701149425, 0.55, 0.174212962962963, 0.209438202247191, 0.408163265306122]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Investors`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      