/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import SectionHeader from "./section-header"
import { UpDown, UpDownNarrow } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import QuoteContent from "../sections/quote-content"
import theme from "../gatsby-plugin-theme-ui";

import { quoteBackground, quoteContentStyle, quoteContentContainer } from './quote.module.scss'

const Quote = ({
  offset,
  factor = 1,
  windowWidth,
  windowHeight,
}) => {

  const { colors } = theme

  return (
    <main>
      <Content sx={{
        variant: `texts.bigger`,
        zIndex: 52,
        backgroundSize: [`200% !important`,null,`contain !important`],
        backgroundPosition: [
          `50% ${ windowHeight < 680 ? `43vw` : windowHeight < 740 ? `77vw` : `20vh` }`,
          `50% 115%`,
          `50% ${windowHeight < 830 ? `115%` : `125%`}`
        ],
        // backgroundColor: `#3472FC34`,
      }} speed={0.1} offset={offset + (windowWidth > 640 && windowHeight > 830 ? 0.1: 0)} factor={factor}
        className={quoteBackground}
      >
        <Inner></Inner>
      </Content>
      <Divider speed={0.3} offset={offset} factor={factor} sx={{
        zIndex: 53,
        width: [`100vw !important`],
      }}>
        <UpDownNarrow>
          <Inner sx={{
            width: `100%`,
            height: `100%`,
            position: `relative`,
          }}>
            <img src="./quote.diamond.png" sx={{
              width: [`186vw`, `50vh`, `55rem`, `61.25rem`, `70rem`],
              position: `absolute`,
              left: [`50%`],
              top: [
                windowHeight < 680 ? `0vw` : windowHeight < 740 ? `62vw` : `2vh`,
                `27%`,
                null,
                null,
                `18%`
              ],
              transform: [`translate(-50%, -50%)`]
            }} />
          </Inner>
        </UpDownNarrow>
      </Divider>
      <Divider speed={windowWidth <= 640 ? 0.15 : 0.4} offset={offset + (windowWidth > 640 && windowHeight > 830 ? 0.06: 0)} factor={factor * (windowWidth <= 640 ? 1 : 0.75)} sx={{
        zIndex: 53,
        width: [`100vw !important`],
      }} className={quoteContentContainer}>
          <Inner sx={{
            width: [`100%`,null,`832px`],
            position: `relative`,
            margin: [
              `${windowHeight < 680 ? `-30vw` : windowHeight < 740 ? `24vw` : `-5vh` } auto 0`,
              `0 auto`
            ],
            textAlign: `center`,
            h2: {
              fontSize: [`6.9vw`, `2.76rem`, `3rem`],
              margin: [`0 1rem 2.65rem` , `0 1rem 4rem`, `0 0 4rem`],
              padding: [`0 0.5rem`, `0 1.2rem`, 0],
              '&:before, &:after': {
                fontSize: [`3rem`, `5rem`],
              },
              '&:before': {
                top: [`-11vw`,`-5%`]
              },
              '&:after': {
                bottom: [`-17vw`,`-35%`]
              },
            },
            p : {
              display: `inline-block`,
              // width: `auto`,
              textAlign: `center`,
              marginX: `auto`,
              marginTop: [`0`, `1rem`],
              '& > a': {
                fontSize: [`5vw`, `1.62rem`, `1.875rem`],
                display: `flex`,
                flexDirection: [`column`, `row`],
                alignItems: `center`,
                textAlign: [`center`, `left`],
                color: colors.text,
                textDecoration: `none`,
                '& > span' : {
                  display: `block`,
                  '& > img': {
                    width: [`3.25rem`,`4.8125rem`],
                    height: [`3.25rem`,`4.8125rem`],
                    marginLeft: [0, `1.375rem`],
                    marginTop: [`0.455rem`, 0]
                  }
                }
              }
            }
          }} className={quoteContentStyle}>
            <QuoteContent />
          </Inner>
      </Divider>
    </main>
  )
}

export default Quote