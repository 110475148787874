/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"

import ProblemContent from "../sections/problem-content"
import ProblemCaptions from "../sections/problem-captions"
import SectionHeader from "./section-header"
import { FONT_SIZES, SECTION_HEADER_CONTAINER_MARGIN } from "../constants/constant"

const Problem = ({
  offset,
  factor = 1,
  windowHeight,
  windowWidth,
}) => {
  return (
    <main>
      <Content speed={0.15} offset={offset} factor={factor} sx={{
        padding: [`0 !important`],
        justifyContent: [`flex-start !important`],
      }}>
        <Inner sx={{
          h4: {
            // fontSize: [`1.444rem`, null, `1.875rem`, null, `2.0625rem`],
            fontSize: FONT_SIZES.subtitle,
            fontWeight: 500,
          },
          p: {
            fontSize: FONT_SIZES.p,
            color: `rgba(255, 255, 255, 0.7)`,
            '& > b': {
              color: `rgba(255, 255, 255, 1)`,
            }
          },
          paddingTop: [
            `calc(((35vh - (16px * 232.51 / 100 * 1.1)) / 2) + (16px * 232.51 / 100 * 1.1))`,
            null,
            `calc(((35vh - (3.445rem * 1.1)) / 2) + (3.445rem * 1.1))`,
            null,
            `calc(((25vh - (3.445rem * 1.1)) / 2) + (3.445rem * 1.1))`
          ],
          display: [`flex`],
          flexDirection: [`column`, `row`],
          justifyContent: [`space-between`],
          alignItems: [`center`, `stretch`],
          width: [ // ROW WIDTH
            `calc(100vw - 1.25rem)`,
            `35rem`,
            `50rem`,
            `60rem`,
            `72.5rem` // 1160
          ],
          '& > div': {
            width: [`calc(100% - 2rem)`, `calc(50% - 0.5rem)`, `calc(50% - 2.5rem)`],
            display: [`flex`],
            flexDirection: [`column`],
            justifyContent: [`center`, `space-between`],
            alignItems: [`center`],
            marginTop: [`3.12rem`, `4.25rem`, `3rem`],
            '& + div': { // NEXT ROW
              // marginTop: [`5rem`, `4.25rem`, `3rem`],
            },
            '& > div': { // COL
              width: [`100%`],
              '&.text': {
                // width: [null, null, null, `calc(50% - 2.5rem)`],
                '& + .graphic': {
                  marginTop: [`1.5rem`]
                }
              },
              '&.graphic': {
                width: [`100%`],
              },
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              '& > img': {
                width: [`87%`, `80%`, `74%`, `68%`],
                marginX: [`auto`]
              },
              '& > h4': {
                margin: 0,
                '& + p': {
                  margin: [`0.889rem 0 0`, null, `1.375rem 0 0`],
                }
              }
            },
          }
        }}>
          <ProblemContent />
        </Inner>
      </Content>
      <Divider speed={0.05} offset={offset + 0.01} factor={factor * 0.35} sx={{zIndex: 54}} sx={{
        pointerEvents: `none`
      }}>
        <SectionHeader
          height={[`${ windowHeight < 590 ? `20` : windowHeight < 740 ? `24` : `14` }vh`, `24vh`, null, null, `17vh`]}
          {...(windowWidth < 380 ? { paddingX: `0` } : null)}
          headerMargin={SECTION_HEADER_CONTAINER_MARGIN}
          // justifyContent={`flex-start`}
        >
          <ProblemCaptions />
        </SectionHeader>
      </Divider>
    </main>
  )
}

export default Problem