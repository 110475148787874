import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className='paragraph'>
  <p>Platypus invents a whole new AMM on Avalanche - Open liquidity single-sided AMM managing risk autonomously based on the coverage ratio, allowing maximal capital efficiency.</p>
  <p>The key concept underpinning Platypus’ design is <b>asset liability management (ALM)</b>. Platypus is the <b>first of its kind</b> to use a single-variant slippage function instead of invariant curves.</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      