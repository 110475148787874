/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import useWindowDimensions from "../utils/use-window-dimensions"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import { headerStyles } from './section-header.module.scss'

const SectionHeader = ({
  children,
  width,
  height,
  justifyContent,
  paddingTop,
  paddingX,
  headerMargin,
  subHeaderMargin,
  headerFontSize,
  subHeaderFontSize,
  ...props
}) => {

  const { height: windowHeight, width: windowWidth } = useWindowDimensions()

  return (
    <Inner sx={{
      width: width !== undefined ? width : [`auto`],
      height: height !== undefined ? height : [`35vh`],
      justifyContent: justifyContent !== undefined ? justifyContent : `center`,
      paddingTop: paddingTop !== undefined ? paddingTop : 0,
      paddingX: paddingX ? paddingX: `0.35rem`,
      h2: {
        fontSize: headerFontSize !== undefined ? headerFontSize : [
          `${ windowWidth < 360 ? `8.9vw` : `232.51%`}`,
          null,
          `3.445rem`
        ],
        margin: headerMargin !== undefined ? headerMargin : [`2.8125rem 1.625rem 0`, `2.8125rem 0 0`],
        '& + h4': {
          margin: subHeaderMargin !== undefined ? subHeaderMargin : [`0.722rem 1.625rem 0`, `0.722rem 0 0`]
        }
      },
      h4: {
        fontSize: subHeaderFontSize !== undefined ? subHeaderFontSize : [
          `${ windowWidth < 360 ? `7.85vw` : `146.25%`}`,
          null,
          `2.167rem`
        ],
      }
    }} className={headerStyles}>
      {children}
    </Inner>
  )
}

export default SectionHeader