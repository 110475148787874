/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import SectionHeader from "./section-header"
import ComparisonGrid from "./comparison-grid"
import ComparisonCaptions from "../sections/comparison-captions"

import { UpDown, UpDownNarrow } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import { SECTION_HEADER_CONTAINER_MARGIN } from "../constants/constant"

const Comparison = ({
  offset,
  factor = 1,
  windowWidth,
  windowHeight,
}) => {

  const tableData = [
    {
      isStub: true,
      rows: [
        {
          isHeader: false, padding: `0 0 0.375rem`,
          borderBottom: `2px solid transparent`, borderRight: `0 none`,
          rowSpan: `1 / 2`,
          display: [`none`, `block`]
        },
        {
          isHeader: true, rowSpan: `2 / 3`,
          display: [`none`, `block`]
        },
        {
          isHeader: false, text: 'Pool Design',
          rowSpan: [`3 / 4`, `3 / 5`],
          alignSelf: `center`
        },
        {
          isHeader: false, text: 'Slippage',
          rowSpan: [`6 / 7`, `5 / 6`],
          alignSelf: `center`
        },
        {
          isHeader: false, text: 'Capital Efficiency',
          rowSpan: [`8 / 9`, `6 / 7`],
          alignSelf: `center`
        },
        {
          isHeader: false, text: 'Asset scalability',
          rowSpan: [`10 / 11`, `7 / 8`],
          alignSelf: `center`
        },
        // {
        //   isHeader: false, text: 'Divergence',
        //   rowSpan: [`12 / 13`, `8 / 9`],
        //   alignSelf: `center`
        // },
      ],
      colSpan: [`1 / span 2`, `1 / span 1`],
    },
    {
      rows: [
        { isHeader: false, text: '1st Gen Stableswap',
          padding: `0 0 0.375rem`, borderBottom: `2px solid transparent`,
          fontWeight: `500`, fontSize: [`0.778rem`, null, `1rem`], textTransform: `uppercase`,
          after: {
            content: `''`,
            display: `block`,
            height: `0.625rem`,
            width: `100%`,
            background: `linear-gradient(90deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%)`,
            opacity: 0.4
          },
        },
        {
          isHeader: true, text: 'Other Stableswaps',
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        {
          isHeader: false, text: 'Closed Pools',
          rowSpan:[`4 / 5`, `3 / 4`],
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        {
          isHeader: false, text: 'Multiple tokens',
          rowSpan:[`5 / 6`, `4 / 5`],
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        {
          isHeader: false, text: 'Low',
          rowSpan:[`7 / 8`, `5 / 6`],
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        {
          isHeader: false, text: 'Medium',
          rowSpan:[`9 / 10`, `6 / 7`],
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        {
          isHeader: false, text: 'Low',
          rowSpan:[`11 / 12`, `7 / 8`],
          borderRight: [`1px solid #686D73`, `0 none`]
        },
        // {
        //   isHeader: false, text: 'Yes',
        //   rowSpan:[`13 / 14`, `8 / 9`],
        //   borderRight: [`1px solid #686D73`, `0 none`]
        // },
      ],
      colSpan: `1 / span 1`,
      rowAutoCount: true
    },
    {
      color: `#01F153`,
      rows: [
        { isHeader: false, text: 'Whole-new World',
          padding: `0 0 0.375rem`, borderBottom: `2px solid transparent`,
          fontWeight: `500`, fontSize: [`0.778rem`, null, `1rem`], textTransform: `uppercase`,
          after: {
            content: `''`,
            display: `block`,
            height: `0.625rem`,
            width: `100%`,
            background: `linear-gradient(270.02deg, rgba(0, 118, 233, 0.5) 0.02%, #00F152 98.75%)`
          }
        },
        { isHeader: true, text: 'Platypus Stableswap', fontWeight: `bold` },
        {
          isHeader: false, text: 'Open liquidity pool', fontWeight: `600`,
          rowSpan:[`4 / 5`, `3 / 4`]
        },
        {
          isHeader: false, text: 'Single sided token', fontWeight: `600`,
          rowSpan:[`5 / 6`, `4 / 5`]
        },
        {
          isHeader: false, text: 'Very Low', fontWeight: `600`,
          rowSpan:[`7 / 8`, `5 / 6`]
        },
        {
          isHeader: false, text: 'High', fontWeight: `600`,
          rowSpan:[`9 / 10`, `6 / 7`]
        },
        {
          isHeader: false, text: 'High', fontWeight: `600`,
          rowSpan:[`11 / 12`, `7 / 8`]
        },
        // {
        //   isHeader: false, text: 'No', fontWeight: `600`,
        //   rowSpan:[`13 / 14`, `8 / 9`]
        // },
      ],
      colSpan: `2 / span 1`,
      rowAutoCount: true
    }
  ]

  const rowTemplateGroup = [
    [
      '2.3125rem', '4rem',
      '2.65rem', '3.6rem', '3.6rem',
      '2.65rem', '3.6rem',
      '2.65rem', '3.6rem',
      '2.65rem', '3.6rem',
      // '2.65rem', '3.6rem'
    ],
    null,
    ['2.3125rem', '4.0625rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', /*'4.6875rem'*/],
    ['2.3125rem', '4.0625rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', /*'4.6875rem'*/],
    ['2.3125rem', '4.0625rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', '4.6875rem', /*'4.6875rem'*/]
  ]

  const contentPaddingTopGrp = [
    windowHeight < 590 ? `42vw` : windowHeight < 740 ? `67vw` : `39vh`,
    `39vh`,
    null,
    null,
    windowHeight < 830 ? `30vh` : `16vh`
  ]

  const paddingCssCal = (contentTemplateGroup, contentPaddingTopGroup) => {

    const paddingGrp = contentPaddingTopGroup.reduce((prevGrp, currGrp) => {
      let prevResult = prevGrp || []
      prevResult.push(currGrp !== null ? currGrp : prevResult[prevResult.length - 1])
      return prevResult
    }, [])

    const sumUpNums = (grp) => {
      const unitPhrase = grp[0].replace(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g, '')
      const sumResult = grp.reduce((prevNum, currNum) => {
        return prevNum + parseFloat(currNum)
      }, 0)
      return sumResult + unitPhrase
    }

    return contentTemplateGroup.map((itm, idx) => {
      return itm === null ? null : `calc((100vh - ${paddingGrp[idx]} - ${sumUpNums(itm)}) / 2)`
    })
  }

  // console.log(844, paddingCssCal(rowTemplateGroup, contentPaddingTopGrp))

  return (
    <main>
      <Content speed={0.15} offset={offset} factor={factor}
        sx={{
          variant: `texts.bigger`,
          padding: `0 !important`,
          zIndex: 53,
        }}
      >
        <Inner sx={{
          width: [`100vw`, null, null, null, '74rem'],
          // overflowX: [`scroll`, null, null, null, `visible`],
          // overflowY: [`visible`],
          paddingTop: contentPaddingTopGrp
        }}>
          <ComparisonGrid data={tableData} rowsTemplateGroup={rowTemplateGroup} windowWidth={windowWidth} />
        </Inner>
      </Content>
      <Divider speed={0.05} offset={offset} factor={factor * 0.35} sx={{zIndex: 53}} sx={{
        pointerEvents: `none`
      }}>
        <SectionHeader
          height={[`62.25vw`, `35vh`, null, null, `32vh`]}
          headerMargin={SECTION_HEADER_CONTAINER_MARGIN}
          // paddingTop={paddingCssCal(rowTemplateGroup, contentPaddingTopGrp)}
          justifyContent={[
            `${ windowHeight < 590 ? `flex-end` : `flex-start`}`,
            null,
            null,
            `center`
          ]}
        >
          <ComparisonCaptions />
        </SectionHeader>
      </Divider>
    </main>
  )
}

export default Comparison