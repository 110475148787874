/** @jsx jsx */
import { keyframes, css, jsx } from "@emotion/react"

const wave = keyframes`
  0% {
    d: path("M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  50% {
    d: path("M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  100% {
    d: path("M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
`

const upDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(30px);
  }
`

const upDownWide = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200px);
  }
`

const upDownNarrow = keyframes`
  from {
    transform: translateY(18px);
  }
  to {
    transform: translateY(0);
  }
`

const upDownAnimation = ({ duration = `4s`, delay = `0s` }) => {
  // const { duration } = props
  return css`
    ${upDown} ${duration} ease-in-out ${delay === `0s` ? `` : delay} infinite alternate;
  `
}

const upDownWideAnimation = css`
  ${upDownWide} 18s ease-in-out infinite alternate;
`

const upDownNarrowAnimation = css`
  ${upDownNarrow} 7s ease-in-out infinite alternate;
`

export function UpDown({ children, ...props }) {
  return (
    <div
      css={css`
        animation: ${upDownAnimation(props)};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      {children}
    </div>
  )
}

export function UpDownWide({ children }) {
  return (
    <div
      css={css`
        animation: ${upDownWideAnimation};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      {children}
    </div>
  )
}

export function UpDownNarrow({ children }) {
  return (
    <div
      css={css`
        animation: ${upDownNarrowAnimation};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      {children}
    </div>
  )
}

export const waveAnimation = (length) => `${wave} ${length} linear infinite alternate`