import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Platypus' AMM facilitates seamless exchange between pegged assets. Its single-sided liquidity provision redefines the way AMM works.`}</h2>
    <div className="author-content">
  <p>
    <a href="https://twitter.com/MrDuckbill" target="_blank">
      <span>Mr Duckbill,<br />Founder of Platypus</span>
      <span>
        <img src="./quote.portrait.png" />
      </span>
    </a>
  </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      