
/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import SectionHeader from "./section-header"
import CommunityChannelsCaptions from "../sections/community-channels-captions"
import GetOutboundLink from "./get-outbound-link"

const CommunityChannels = () => {

  const channels = [
    {
      title: 'Twitter',
      icon: './sn-logo-twitter.inline.svg',
      url: 'https://twitter.com/Platypusdefi',
      width: `61.63%`, height: `50.00%`
    },
    {
      title: 'Telegram',
      icon: './sn-logo-telegram.inline.svg',
      url: 'https://t.me/Platypusdefi',
      width: `58.14%`, height: `58.14%`
    },
    {
      title: 'Discord',
      icon: './sn-logo-discord.inline.svg',
      url: 'https://discord.gg/B6ThAvev2A',
      width: `67.44%`, height: `51.41%`
    },
    {
      title: 'Medium',
      icon: './sn-logo-medium.inline.svg',
      url: 'https://medium.com/platypus-finance',
      width: `75.58%`, height: `42.92%`
    }
  ]

  return (
    <div>
      <SectionHeader height={`auto`}>
        <CommunityChannelsCaptions />
      </SectionHeader>
      <div sx={{
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
        alignItems: `center`,
        width: [`calc(100vw - 2.25rem)`, `28.625rem`],
        margin: [`0 auto`]
      }}>
        
        {channels.map((channel, index)=>{
          return (
            <div
              key={'channel-button-container-' + channel.title}
              sx={{
                width: [`19vw`, `21vw`, `5.375rem`],
                height: [`19vw`, `21vw`, `5.375rem`],
                display: `block`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <GetOutboundLink
                key={'channel-' + index + '-' + channel.title.toLowerCase()}
                href={channel.url} target='_blank'
                sx={{
                  width: [`100%`],
                  height: [`100%`],
                  display: `block`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
                eventProps={{
                  event_action: 'Click on Community',
                  event_category: 'Follower Aquisition',
                  event_label: channel.title
                }}
              >
                <img src={channel.icon}
                  sx={{
                    width: [`${parseFloat(channel.width) * 0.87}%`, channel.width],
                    height: [`${parseFloat(channel.height) * 0.87}%`, channel.height],
                    display: `block`,
                    margin: `0 !important`,
                  }}
                />
              </GetOutboundLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CommunityChannels