import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <div className="text">
    <h4>Liquidity Fragmentation</h4>
    <p>One of the major problems found in the first generation stableswaps’ Closed liquidity pools is liquidity fragmentation, where the liquidity of different pools cannot be shared with one another, resulting in <b>higher slippage</b>.</p>
  </div>
  <div className="graphic">
    <img src="./graphic-problem-slippage.png" />
  </div>
    </div>
    <div>
  <div className="text">
    <h4>Bad User Experience</h4>
    <p>The design of other stableswaps requires multiple tokens of equal value within a pool, often complicating its pool compositions (pairing up LP token with new tokens). It significantly <b>hinders the scalability of the protocol</b> and leads to <b>bad user experience</b>.</p>
  </div>
  <div className="graphic">
    <img src="./graphic-problem-bad-ux.png" />
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      