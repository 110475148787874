/** @jsx jsx */
import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { jsx } from "theme-ui"
import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import AppContext from '../context/AppContext'
import useWindowDimensions from "../utils/use-window-dimensions"
import MainVisual from "../components/main-visual"
import Problem from '../components/problem'
import Solutions from "../components/solutions"
import Comparison from "../components/comparison"
import Quote from '../components/quote'
import Roadmap from '../components/roadmap'
import Investors from '../components/investors'
import Audits from '../components/audits'
import Footer from "../components/footer"
import { OutlineButton } from "../components/button"
import GetOutboundLink from '../components/get-outbound-link'
import HoverList from '../components/hover-list'
// import Feature from "../components/feature"
// import SectionLink from "../components/section-link"
// import Logos from "../components/logos"
// import FeatureContentAmm from "../sections/feature-content-amm"
// import FeatureContentStablecoin from "../sections/feature-content-stablecoin"
// import FeatureContentLending from "../sections/feature-content-lending"
// import FeatureVisualAmm from "../sections/feature-visual-amm"
// import FeatureVisualStablecoin from "../sections/feature-visual-stablecoin"
// import FeatureVisualLending from "../sections/feature-visual-lending"

// import Hero from "@lekoarts/gatsby-theme-cara/src/components/hero"
// import Projects from "gatsby-theme-cara/src/components/projects"
// import About from "gatsby-theme-cara/src/components/about"
// import Contact from "../components/contact"
// import '../css/global.css'

const Landing = ({path}) => {
  
  console.log('>>>>>> ?? Landing path ?? <<<<<< : ', path)

  const [toBePage, setToBePage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [alreadyPage, setAlreadyPage] = useState(0)
  const [toShowHeader, setToShowHeader] = useState(false)

  const [solutionCapTopPosOffset, setSolutionCapTopPosOffset] = useState(0)
  const [solutionCapOpacity, setSolutionCapOpacity] = useState(1)

  const toBePageRef = useRef(0)
  const currentPageRef = useRef(0)
  const alreadyPageRef = useRef(0)

  const { height, width } = useWindowDimensions()
  const {
    items,
    addMenuItem,
    headerWidth,
    setHeaderWidthTo,
    setHeaderTransparency,
    setRecalculationOnResize,
  } = React.useContext(AppContext);
  const isShownAppLaunchBtn = Boolean(process.env.LAUNCH_APP_BUTTON === 'TRUE')

  useEffect(() => {
    // document.querySelector('#gatsby-focus-wrapper main > div').addEventListener('scroll', handleScroll);
    console.log('O██O██OOO██O██O')
    console.log('██OOO██O██OOO██')
    console.log('█OO█OO███OO█OO█')
    console.log('██OOO██O██OOO██')
    console.log('O██O██OOO██O██O')
    console.log('CHECK')
    setHeaderTransparency(true);
    setRecalculationOnResize(true);
    // if (mainVisualRef.current !== null && mainVisualRef.current.clientWidth > 0 && headerWidth === null) {
    //   console.log('░░::░░::░░ " o " ░░::░░::░░ : Header width recalculator => ', mainVisualRef.current.clientWidth)
    //   setHeaderWidthTo(mainVisualRef.current.clientWidth + 0)
    // }
    addMenuItem([{
      label: 'NFT Whitepaper',
      href: '/nft',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Page Rediret',
        event_label: 'NFT Whitepaper'
      },
      viewability: '111'
    },{
      label: 'Investors',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Investors'
      },
      onClick: ()=>scroll(getAccmOffsetFor('investors'), getAccmExtraOffsetFor('investors')),
      viewability: '111'
    },{
      label: 'Roadmap',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Roadmap'
      },
      onClick: ()=>scroll(getAccmOffsetFor('roadmap'), getAccmExtraOffsetFor('roadmap')),
      viewability: '111'
    },{
      label: 'Community',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Follower Aquisition',
        event_label: 'Community'
      },
      onClick: ()=>scroll(getAccmOffsetFor('footer'), getAccmExtraOffsetFor('footer') * 0.9),
      viewability: '101'
    }], 1)
  }, [])

  const parallaxRef = useRef()
  const mainVisualRef = useRef(null)
  const refSolutions = useRef(null)

  useEffect(()=>{
    // console.log('>>>>?>> Extra for solutions : ', offsets.solutions.extra)
    // console.log('>>>>?>> width : ', width)
    // console.log('>>>>?>> height : ', height)
  },[height])

  useEffect(() => {
    // console.log('%%%O%%%')
    // console.log('%O%O%O%')
    // console.log('%%OOO%%')
    // console.log('%%%O%%%')
    // console.log('mainVisualRef', mainVisualRef)
    // console.log('mainVisualRef.offsetWidth', mainVisualRef.current.offsetWidth)
    // console.log('mainVisualRef.clientWidth', mainVisualRef.current.clientWidth)
    console.log('%%%O%%%')
    console.log('parallaxRef', parallaxRef)
    // console.log('parallaxRef.offsetWidth', parallaxRef.current.offsetWidth)
    // console.log('parallaxRef.clientWidth', parallaxRef.current.clientWidth)
    if (
      mainVisualRef.current !== null &&
      mainVisualRef.current.clientWidth > 0 &&
      (headerWidth === null || mainVisualRef.current.clientWidth !== headerWidth)
    ) {
      setHeaderWidthTo(mainVisualRef.current.clientWidth + 0)
    }
  })

  // useEffect(()=>{
  //   console.log(`==== [ ${toBePage}, ${currentPage}, ${alreadyPage} ] ====`)
  // },[toBePage, currentPage, alreadyPage])

  useLayoutEffect(() => {
    const topPos = (element) => element !== null ? element.getBoundingClientRect().top : 0
    const eleHeight = (element) => element !== null ? element.getBoundingClientRect().height : 0
    // let div3Pos = topPos(refSolutions.current)

    const solutionCapAniTopPosRange = [0.0326, -0.757]
    const solutionCapAniPosMaxRatio = 0.2

    const handleScroll = () => {
      // console.log("Scroll captured!!")
      // console.log('parallaxRef', parallaxRef)
      // console.log('parallaxRef.current ', parallaxRef.current)
      // 110
      const { space, current } = parallaxRef.current
      let solutionsCapPos = topPos(refSolutions.current)
      let solutionsCapHeight = eleHeight(refSolutions.current)
      let solutionCapAniPosMax = solutionsCapHeight * solutionCapAniPosMaxRatio
      
      // console.log(`>> ${current} / ${space}`, parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()))
      // console.log('>>>>?>> solutionsCapPos : ', solutionsCapPos)
      // console.log('>>>>?>> solutionsCapHeight : ', solutionsCapHeight)
      // console.log('>>>>?>> solutionsCapPos / solutionsCapHeight : ', solutionsCapPos / solutionsCapHeight)
      if ( solutionsCapPos / solutionsCapHeight > solutionCapAniTopPosRange[0] ) {
        setSolutionCapTopPosOffset(0)
        setSolutionCapOpacity(1)
      }
      if (
        solutionsCapPos / solutionsCapHeight < solutionCapAniTopPosRange[0] &&
        solutionsCapPos / solutionsCapHeight >= solutionCapAniTopPosRange[1]
      ) {
        let progress = (solutionsCapPos / solutionsCapHeight) - solutionCapAniTopPosRange[0]
        let base = solutionCapAniTopPosRange[1] - solutionCapAniTopPosRange[0]
        let progressPercentage = progress / base
        let result = solutionCapAniPosMax * progressPercentage * -1
        let opacity = 1 * 1 - progressPercentage
        // console.log('>> #!# >> progressPercentage : ', progressPercentage)
        // console.log('>> #!# >> result : ', result)
        setSolutionCapTopPosOffset(result)
        setSolutionCapOpacity(opacity)
      }
      else if ( solutionsCapPos / solutionsCapHeight < solutionCapAniTopPosRange[1] ) {
        setSolutionCapTopPosOffset(solutionCapAniPosMax * -1)
        setSolutionCapOpacity(0)
      }
      // console.log('>>>>?>> refSolutions : ', refSolutions)
      // console.log('>>>>?>> refSolutions.current : ', refSolutions.current)
      // console.log('>>>>?>> refSolutions.current.getBoundingClientRect : ', refSolutions.current.getBoundingClientRect())
      // console.log('>>>>?>> refSolutions.current.getBoundingClientRect().top : ', refSolutions.current.getBoundingClientRect().top)
  
      const toBePageIndex = Math.ceil(current / space)
      const currPageIndex = Math.round(current / space)
      const alrdPageIndex = Math.floor(current / space)
  
      toBePageRef.current = Math.ceil(current / space)
      currentPageRef.current = Math.round(current / space)
      alreadyPageRef.current = Math.floor(current / space)
  
      // console.log(`toBePageIndex : ${toBePageIndex}, currPageIndex : ${currPageIndex}, alrdPageIndex : ${alrdPageIndex}`)
  
      if (toBePage !== toBePageIndex) {
        setToBePage(toBePageIndex)
      }
      if (currentPage !== currPageIndex) {
        setCurrentPage(currPageIndex)
      }
      if (alreadyPage !== alrdPageIndex) {
        setAlreadyPage(alrdPageIndex)
      }
  
      if (current >= (space * 2 / 3) && !toShowHeader) {
        // alert("to show");
        setToShowHeader(true)
        setHeaderTransparency(false)
      }
      if (current < (space * 2 / 3)) {
        // console.log('What is toShowHeader ? ', toShowHeader)
        setToShowHeader(false)
        setHeaderTransparency(true)
      }
  
      // if (parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()) >= 110 && !toShowHeader) {
      //   setToShowHeader(true)
      // }
      // if (parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()) < 110 && toShowHeader) {
      //   setToShowHeader(false)
      // }
    }

    document.querySelector('#gatsby-focus-wrapper main > div').addEventListener('scroll', handleScroll)
    return () => document.querySelector('#gatsby-focus-wrapper main > div').removeEventListener('scroll', handleScroll)
  }, [])

  // const handleScroll = useCallback(() => {
  //   // console.log("Scroll captured!!")
  //   // console.log('parallaxRef', parallaxRef)
  //   // console.log('parallaxRef.current ', parallaxRef.current)
  //   // 110
  //   const { space, current } = parallaxRef.current
  //   console.log(`>> ${current} / ${space}`, parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()))

  //   const toBePageIndex = Math.ceil(current / space)
  //   const currPageIndex = Math.round(current / space)
  //   const alrdPageIndex = Math.floor(current / space)

  //   toBePageRef.current = Math.ceil(current / space)
  //   currentPageRef.current = Math.round(current / space)
  //   alreadyPageRef.current = Math.floor(current / space)

  //   // console.log(`toBePageIndex : ${toBePageIndex}, currPageIndex : ${currPageIndex}, alrdPageIndex : ${alrdPageIndex}`)

  //   if (toBePage !== toBePageIndex) {
  //     setToBePage(toBePageIndex)
  //   }
  //   if (currentPage !== currPageIndex) {
  //     setCurrentPage(currPageIndex)
  //   }
  //   if (alreadyPage !== alrdPageIndex) {
  //     setAlreadyPage(alrdPageIndex)
  //   }

  //   if (current >= (space * 2 / 3) && !toShowHeader) {
  //     // alert("to show");
  //     setToShowHeader(true)
  //   }
  //   if (current < (space * 2 / 3)) {
  //     // console.log('What is toShowHeader ? ', toShowHeader)
  //     setToShowHeader(false)
  //   }

  //   // if (parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()) >= 110 && !toShowHeader) {
  //   //   setToShowHeader(true)
  //   // }
  //   // if (parseInt(toBePageRef.current.toString() + currentPageRef.current.toString() + alreadyPageRef.current.toString()) < 110 && toShowHeader) {
  //   //   setToShowHeader(false)
  //   // }
  // },[toShowHeader])

  const scroll = (to, offset = 2) => {
    // console.log("Click captured!!")
    // console.log('parallaxRef.current ', parallaxRef.current)
    if (parallaxRef.current) {
      parallaxRef.current.scrollTo(to + offset)
    }
    if (to + offset === 0) {
      setToShowHeader(false)
      setHeaderTransparency(true)
    }
  }

  const sideBarLinks = ['AMM', 'Stablecoin', 'Lending']
  const offsets = {
    mainVisual: {
      length: 1,
      extra: width <= 640 ?
        height < 590 ? -0.03 : height < 746 ? 0 : height < 845 ? -0.05 : 0
        : 0
    },
    problem: {
      length: 0.83,
      extra: width <= 640 ?
        height < 590 ? 0.79 : height < 680 ? 0.65 : height < 746 ? 0.6 :  height < 845 ? 0.32 : 0
        : height < 830 ?
          0.05 : 0,
    },
    solutions: {
      length: 0.91,
      extra: width <= 640 ?
        height < 590 ? 1.5 : height < 680 ? 1.4 :height < 746 ? 1.25 : height < 845 ? 0.98 : 0
        : height < 830 ?
          0.1 : 0,
    },
    comparison: {
      length: 1.15,
      extra: width <= 640 ?
        height < 590 ? 0.56 : height < 680 ? 0.16 : height < 746 ? 0.05 : height < 845 ? -0.05 : 0
        : height < 830 ?
          -0.03 : 0,
    },
    quote: {
      length: 0.3, //0.45
      extra: width <= 640 ?
        height < 590 ? 0.17 : height < 680 ? 0.27 : height < 746 ? 0.33 : height < 845 ? 0.23 : 0
        : height < 830 ?
          0.25 : 0,
    },
    investors: {
      length: 0.72, // 0.65
      extra:
        width <= 640 ?
          height < 590 ? 0.86 : height < 680 ? 0.63: height < 746 ? 0.45 : height < 845 ? 0.35 : height < 900 ? 0.38 :0
          : height < 830 ?
            0.25 : 
        width <= 832 ?
          0.06 :
        width <= 1024 ?
          0.09 :
        height < 830 ?
          0 :
          0,
    },
    audits: {
      length: 0.27,
      extra: width <= 640 ?
        // height < 680 ? 0.05 :
        height < 746 ? 0.07 :
        height < 845 ? 0 : 0
      :
      width <= 832 ?
        0
      :
      width < 1024 ? 0 :
        height < 830 ? 0.23 : 0,
    },
    roadmap: {
      length: 0.78, // 0.72
      extra:
        width <= 640 ?
          height < 590 ? 0.255 : height < 746 ? 0.28 : height < 845 ? 0.2 : height < 852 ? 0.17 : height < 900 ? 0.2 : 0
        :
        width <= 832 ?
          0.35
        :
          height < 830 ?
            0.02 : 0,
    },
    footer: {
      length: 0.83, // 0.8
      extra: width <= 640 ?
        height < 590 ? 0.3 : height < 680 ? 0.22 : height < 746 ? 0.25: height < 845 ? 0.1 : 0
        : height < 830 ?
          0.04 : 0,
    },
  }

  const getAccmOffsetFor = (name) => {
    if (Object.keys(offsets).indexOf(name) < 0) {
      return 0
    }
    const { index: nameIdx } = Object.keys(offsets)
      .map((itm, idx) => { return { key: itm, index: idx }})
      .filter((itm, idx) => itm.key === name)[0]
    
    const result = Object.keys(offsets)
      .filter((name, idx) => idx < nameIdx)
      .reduce((accum, key) => accum + offsets[key].length, 0)
    return result
  }

  const getAccmExtraOffsetFor = (name) => {
    if (Object.keys(offsets).indexOf(name) < 0) {
      return 0
    }
    const { index: nameIdx } = Object.keys(offsets)
      .map((itm, idx) => { return { key: itm, index: idx }})
      .filter((itm, idx) => itm.key === name)[0]
    
    const result = Object.keys(offsets)
      .filter((name, idx) => idx < nameIdx)
      .reduce((accum, key) => accum + offsets[key].extra, 0)
    return result
  }

  return (
    <Layout>
      <Parallax
        pages={Object.keys(offsets).reduce((accum, key) => accum + offsets[key].length, 0) + Object.keys(offsets).reduce((accum, key) => accum + offsets[key].extra, 0)}
        ref={parallaxRef}
      >
        <MainVisual
          offset={getAccmOffsetFor('mainVisual')}
          factor={offsets.mainVisual.length + offsets.mainVisual.extra}
          scrollTo={scroll} scrollPos={{
            footer: [getAccmOffsetFor('footer'), getAccmExtraOffsetFor('footer')]
          }}
          ref={mainVisualRef}
        />
        <Problem
          offset={getAccmOffsetFor('problem') + getAccmExtraOffsetFor('problem')}
          factor={offsets.problem.length + offsets.problem.extra}
          windowWidth={width} windowHeight={height}
        />
        <Solutions
          offset={getAccmOffsetFor('solutions') + getAccmExtraOffsetFor('solutions')}
          factor={offsets.solutions.length + offsets.solutions.extra}
          scrollTo={scroll}
          windowWidth={width} windowHeight={height}
          ref={refSolutions}
          captionTopPosOffset={solutionCapTopPosOffset}
          captionOpacity={solutionCapOpacity}
        />
        <Comparison
          offset={getAccmOffsetFor('comparison') + getAccmExtraOffsetFor('comparison')}
          factor={offsets.comparison.length + offsets.comparison.extra}
          windowWidth={width} windowHeight={height}
        />
        <Quote
          offset={getAccmOffsetFor('quote') + getAccmExtraOffsetFor('quote')}
          factor={offsets.quote.length + offsets.quote.extra}
          windowWidth={width} windowHeight={height}
        />
        <Investors
          offset={getAccmOffsetFor('investors') + getAccmExtraOffsetFor('investors')}
          factor={offsets.investors.length + offsets.investors.extra}
          windowWidth={width} windowHeight={height}
        />
        <Audits
          offset={getAccmOffsetFor('audits') + getAccmExtraOffsetFor('audits')}
          factor={offsets.audits.length + offsets.audits.extra}
          windowWidth={width} windowHeight={height}
        />
        <Roadmap
          offset={getAccmOffsetFor('roadmap') + getAccmExtraOffsetFor('roadmap')}
          factor={offsets.roadmap.length + offsets.roadmap.extra}
          windowWidth={width} windowHeight={height}
        />
        <Footer
          offset={getAccmOffsetFor('footer') + getAccmExtraOffsetFor('footer')}
          factor={offsets.footer.length + offsets.footer.extra}
          windowWidth={width} windowHeight={height}
          menuItems={items}
        />
      </Parallax>
      {/* <Layout>
        <Parallax pages={5}>
          <Hero offset={0} factor={1} />
          <Projects offset={1} factor={2} />
          <About offset={3} factor={1} />
          <Contact offset={4} factor={1} />
        </Parallax> 
      </Layout>*/}
    </Layout>
  )
}

export default Landing